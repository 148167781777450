import React, { useContext } from "react";
import { BsXLg } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { BreadCrumbContext } from "../../context/breadcrumbcontext/BreadCrumbContext";
import { getUrlParams } from "../../utility/utility";
import "./backButton.css";

export const BackButton = ({
  children,
  pathToBeRedirected,
  className,
  allowRedirection,
}) => {
  const navigate = useNavigate();
  const { ResetBreadCrumb3LevelTitle } = useContext(BreadCrumbContext);

  const goBack = () => {
    ResetBreadCrumb3LevelTitle();
    // const fromManageTeam = window.location.pathname === "/yourchannel" ? "&fromManageTeam=true" : "";

    const prevPath = getUrlParams("prevPath");
    const backButtonParams = `?loadFromCache=true&backButton=true`;

    if (getUrlParams("BB") === "true" || getUrlParams("BB") === true) {
      navigate(`${pathToBeRedirected}${backButtonParams}`);
    } else if (prevPath) {
      navigate(`${prevPath}${backButtonParams}`);
    } else if (pathToBeRedirected) {
      navigate(`${pathToBeRedirected}${backButtonParams}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div className={className || "close-btn"}>
      {children}
      <BsXLg
        tabIndex={0}
        title="Close"
        aria-label="Close"
        role="button"
        onClick={allowRedirection ? goBack : undefined}
        className={`close-btn-icon ${
          allowRedirection ? "" : "actionIcon-disable"
        }`}
        onKeyDown={(e) => {
          if (e.key === "Enter" && allowRedirection) {
            goBack();
          }
        }}
      />
    </div>
  );
};
