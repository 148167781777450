/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import PageNotFound from "../../../../commonui/pagenotfound/pageNotFound";
import ReusableLoader from "../../../../commonui/reusableloader/reusableLoader";
import { UseToasterNotificationsContext } from "../../../../context/toasternotificationscontext/toasterNotificationsContext";
import DialogBox from "../../../../commonui/dialogBox/DialogBox";
import { BackButton } from "../../../../commonui/backbutton/backButton.jsx";
import Constant from "../../../../utility/constants";
import { MdZoomIn, MdZoomOut } from "react-icons/md";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { Viewer, Worker, Button } from "@react-pdf-viewer/core";
import { UseUserInfoContext } from "../../../../context/usercontext/userContext";
import { printPlugin } from "@react-pdf-viewer/print";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { navigateBackToHome } from "../../../../utility/redirectToHome";
import { TfiWorld } from "react-icons/tfi";
import { HiDownload } from "react-icons/hi";
import { decodeString, checkIfDeviceIsMob } from "../../../../utility/utility";
import TelemetryEventNames from "../../../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../../../services/appInsights";
import { app } from "@microsoft/teams-js";
import { postData, baseAxiosUrl } from "../../../../services/customApis";
import APIEndPoints from "../../../../utility/apiendpoints";
import { Web_Version_Message } from "../../../../commonui/staticDialogMessage/staticDialogMessage";
import { GrClose } from "react-icons/gr";
import { Image } from "@fluentui/react-northstar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "./pdfDisplay.css";
import { BsXLg } from "react-icons/bs";
import { useNavigate, useLocation } from 'react-router-dom';

export default function PdfDisplay(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedFile, activeTab, showComponent, promptQuestionsHeight, internalAppDetails} = props;
  const docTitle = activeTab?.title;
  const { userInfo } = UseUserInfoContext();
  const contentTypeMapping = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: { isTPAlerts: true },
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: { isFootNotes: true },
    // Add more content types here as needed
  };

  const progBar = (numLoadedPages, numPages, onCancel) => (
    <div
    className="page-progress-bar-container"
    >
      <div
       className="page-progress-bar-body"
      >
        <div className="page-progress-bar-loader">
          Preparing {numLoadedPages}/{numPages} pages ...
        </div>
        <div className="page-progress-bar-loader">
          <Loader />
        </div>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );

  const printPluginInstance = printPlugin({
    renderProgressBar: progBar,
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageInput, GoToPreviousPage, GoToNextPage, NumberOfPages } =
    pageNavigationPluginInstance;
  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut, ZoomPopover } = zoomPluginInstance;
  const [errorApiCall, setErrorApiCall] = useState(false);
  const { handleNotificationMsgsData, notificationMsgsData } = UseToasterNotificationsContext();
  const [url, setUrl] = useState("");
  const [link, setLink] = useState([]);
  const [accessDeniedMsg, setAccessDeniedMsg] = useState(false);
  const [divStyle, setDivStyle] = useState({});
  const [webVersionDisable, setWebVersionDisable] = useState(false);


  useEffect(() => {
    if (selectedFile?.fileDetail && Object.keys(selectedFile?.fileDetail).length > 0) {
      // Check if 'link' already contains an object with a key matching 'metaDataId'
      let fileDetails = {
        repositoryType: selectedFile?.fileDetail?.repositoryType,
        containerId: selectedFile?.fileDetail?.containerId,
        directoryIdentifier: selectedFile?.fileDetail?.directoryIdentifier,
        fileIdentifier: selectedFile?.fileDetail?.fileIdentifier,
        actualFileName: selectedFile?.fileDetail?.actualFileName,
        fileSize: selectedFile?.fileDetail?.fileSize,
        fileType: selectedFile?.fileDetail?.fileType,
        fileDownloadUrl: selectedFile?.fileDetail?.fileDownloadUrl,
      };
      const isMetaDataIdPresent = link.some(obj => obj.hasOwnProperty(props?.activeTab?.metaDataId));

      if (isMetaDataIdPresent) {
        // If true, return early to avoid making the API call
        return;
      }

      postData(fileDetails, APIEndPoints.GET_FILE_TOKEN).then(response => {
        let bUrl = baseAxiosUrl + APIEndPoints.DOWNLOAD_PDF_WITH_TOKEN + response.data;
        let responseObj = {
          [props?.activeTab?.metaDataId]: bUrl,
        };
        setLink(prevData => [...prevData, responseObj]);
        // console.log("link", link);
      });
    }

  }, [selectedFile?.fileDetail]);

  useEffect(() => {
    if (selectedFile?.fileType === "pdf") {
      const blobData = base64toBlob(selectedFile?.fileStream);
      const url = URL.createObjectURL(blobData);
      setUrl(url);
    }
  }, [selectedFile]);
  useLayoutEffect(() => {
    setDivStyle({
      border: "1px solid rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    });
  }, []);

  useEffect(() => {
    const handleHeight = (ev) => {

      setDivStyle({
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",

      });
    };

    try {
      window.addEventListener("resize", handleHeight, true);
    } catch (e) {
      console.log(e);
    }
    return () => {
      window.removeEventListener("resize", handleHeight, true);
    };
  }, []);

  const handleBackButtonClick = () => {

    navigate("/apps/LibrarySearchPage", {
      state: {
        appDetails: internalAppDetails,
        privilagesPowerKApp: location?.state?.privilagesPowerKApp,
        templateDetails: location?.state?.templateDetails,
      }
    });
  };

  const base64toBlob = (data) => {
    let base64WithoutPrefix = data.replace(
      "data:application/pdf;base64,", ""
    );
    base64WithoutPrefix = base64WithoutPrefix.replace(
      "data:application/octet-stream;base64,", ""
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  // opening the document in new window
  const openInNewTab = () => {
    const metaDataId = props?.activeTab?.metaDataId;
    const selectedLinkObj = link.find(obj => obj.hasOwnProperty(metaDataId));
    const selectedUrl = selectedLinkObj ? selectedLinkObj[metaDataId] : "";
    const url = selectedUrl;
    if (url != "" && userInfo && userInfo.loggedInWeb !== undefined) {
      //TESTED
      TrackEvent(TelemetryEventNames.UCC_OPEN_PDF_IN_BROWSER_EVENT, userInfo, {
        BrowserUrl: url ?? "",
      });

      userInfo.loggedInWeb === true
        ? window.open(url, "_blank")        
        : app.openLink(url);
    }
  };

  const showNotification = (msg, type) => {
    handleNotificationMsgsData({
      showMsgBar: true,
      started: false,
      completed: true,
      msg,
      type,
      isFailed: false,
    });
  };
  // Utility function to download a file from a blob
  const downloadBlob = (blob, filename) => {
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const downloadFileFromBase64Url = async (base64Url, filename) => {
    try {
      const blob = base64toBlob(base64Url);
      downloadBlob(blob, filename);
      return true;
    } catch (error) {
      console.error('Error downloading file:', error);
      showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG, "Fail");
      return false;
    }
  };
  // get file stream using API endpoint specific for internal apps
  const getFileStreamDetails = async (attachment) => {
    const { contentTypeId, appID } = internalAppDetails;
    const companyId = userInfo?.teams?.companyId;
    try {
      const response = await postData({
        metaDataId: 0,
        appId: appID,
        contentType: contentTypeId,
        attachments: [attachment],
      }, APIEndPoints.DOWNLOAD_ATTACHMENT_FOR_INTERNALAPPS(contentTypeId, companyId));
      if (!response?.data) {
        console.error("Error fetching file stream details");
        setErrorApiCall(true);
        return null;
      }
      return response.data;
    } catch (error) {
      // Conditional logging for development mode
      if (process.env.NODE_ENV === 'development') {
        console.error("Error fetching file stream details:", error);
      }
      const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
      if (errorMessage?.includes("access denied")) {
        setAccessDeniedMsg(true);
      } else {
        setErrorApiCall(true);
      }
      return null;
    }
  };

  const handleDownload = async () => {
    showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_INIT_MSG, "Info");
    const { fileType, attachment } = selectedFile || {};
    const isHtmlFile = fileType === "html";
    const isTPAlerts = contentTypeMapping[internalAppDetails?.contentTypeId]?.isTPAlerts || false;
    const isFootNoteApp = contentTypeMapping[internalAppDetails?.contentTypeId]?.isFootNotes || false;
    const hasFileDownloadUrl = Boolean(attachment?.fileDetail);
    const attachmentAvailable = isHtmlFile && isTPAlerts && hasFileDownloadUrl;

    const fileDownloadUrl = attachmentAvailable
      ? await getFileStreamDetails(attachment)
      : selectedFile?.fileStream;
    const fileExtension = attachmentAvailable ? 'pdf' : 'html';
    const fileIdentifier = selectedFile?.fileDetail?.fileIdentifier;
    const fileNameWithoutExtension = isFootNoteApp && fileIdentifier ? fileIdentifier.replace(/\.[^/.]+$/, "") : "";
    const downloadFileName = `${docTitle || fileNameWithoutExtension}.${fileExtension}`;
    const isDownload = await downloadFileFromBase64Url(fileDownloadUrl, downloadFileName);
    const message = isDownload
      ? Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_SUCCESS_MSG
      : Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG;
    const type = isDownload ? "Success" : "Fail";
    showNotification(message, type);
  };
  if (errorApiCall)
    return (
      <>
        <PageNotFound />
      </>
    );

  if (accessDeniedMsg)
    return (
      <DialogBox
        closeOnOutsideClick={false}
        confirmButton={"Ok"}
        onConfirmMethod={() => {
          navigateBackToHome();
        }}
        content={
          <p>
            <br />
            {Constant.ACCESSDENIED_DETAILSPAGE}
            <br />
          </p>
        }
        backdrop={true}
        openDialog={accessDeniedMsg}
        className={"dialogStyles yelloBtn"}
      />
    );
  return (
    <>
      {selectedFile?.isLoading === true ? (
        <ReusableLoader />
      ) : (
        <div className={`pdfDisplayer ${!showComponent ? "addPadding" : ""
          }`}>
          <div>
            {selectedFile?.fileType === "pdf" && (
              <Provider theme={teamsTheme}>
                <div className="tab-aligner" style={{ height: `${promptQuestionsHeight}px` }}>
                  <div
                    style={divStyle}
                    data-testid="pdfDisplay"
                    className={`document-card ${notificationMsgsData.showMsgBar ? "addMarginTop" : ""
                      }`}
                  >
                    <div
                      className="btn-container pdfDisplayBtnContainer"
                    >
                      <span className="pdftitle" title={docTitle}>{docTitle}</span>
                      <div className="functional-btns actionBar-pdfDisplay">
                        <div className="pdf-display-actionitems">
                          <GoToPreviousPage>
                            {(props) => (
                              <AiOutlineUp
                                onClick={props.onClick}
                                data-testid="Previous"
                                className={`${props.isDisabled ? "actionIcon-disable" : ""
                                  }`}
                              />
                            )}
                          </GoToPreviousPage>
                        </div>
                        <div
                        className="currentPageInput"
                        >
                          <CurrentPageInput />
                        </div>

                        <div className="pdf-display-actionitems">
                          <NumberOfPages />
                        </div>
                        <div className="pdf-display-actionitems">
                          <GoToNextPage>
                            {(props) => (
                              <AiOutlineDown
                                data-testid="Next"
                                onClick={props.onClick}
                                className={`${props.isDisabled ? "actionIcon-disable" : ""
                                  }`}
                              />
                            )}
                          </GoToNextPage>
                        </div>
                        <div
                          className="gap-pdfpage-pdfzoom"
                        >
                          <ZoomOut>
                            {(props) => (
                              <MdZoomOut
                                data-testid="ZoomOut"
                                onClick={props.onClick}
                              />
                            )}
                          </ZoomOut>
                        </div>
                        <div className="addPaddingLgCursor">
                          <ZoomPopover />
                        </div>
                        <div className="addPaddingLgCursor">
                          <ZoomIn>
                            {(props) => (
                              <MdZoomIn
                                data-testid="ZoomIn"
                                onClick={props.onClick}
                              />
                            )}
                          </ZoomIn>
                        </div>
                        <div className="addPaddingLgCursor" >
                          <div onClick={openInNewTab} rel="noopener noreferrer" className="anchorLink">
                            <TfiWorld
                              tabIndex={0}
                              title={Constant.INTERNALAPP_CONSTANTS.LINK_TITLE}
                              className={`${userInfo.loggedInWeb && "disable-web-version"}`}
                            />
                          </div>
                        </div>
                        {!checkIfDeviceIsMob() &&
                          <div className="mobileDownloadIcon">
                            <a href={url} download={docTitle + ".pdf"} className="anchorLink">
                              <HiDownload className="datagrid_icon_mb" title={Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_TITLE} />
                            </a>
                          </div>}
                          {internalAppDetails?.contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES && (
                            <BsXLg
                              title="Close"
                              className="footNotes-close-btn"
                              onClick={() => handleBackButtonClick()}
                            />
                          )}
                      </div>
                    </div>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                      <div
                        data-testid="pdf-Containerdiv-testid"
                        className="pdf-container-custom pdf-container-overflowHide"
                      >
                        <Viewer
                          fileUrl={url}
                          plugins={[
                            printPluginInstance,
                            pageNavigationPluginInstance,
                            zoomPluginInstance,
                          ]}
                        />
                      </div>
                    </Worker>
                  </div>
                </div>
              </Provider>
            )}

            {selectedFile?.fileType === "html" && (
              <div className="htmlCard" style={{ height: `${promptQuestionsHeight}px` }}>
                <div className="htmlTitleContainer">
                  <div className="htmlTitle" title={docTitle}>{docTitle}</div>
                  <div className="htmlLinksContainer">
                    <div className="pdf-display-actionitems">
                      <div className="pdf-display-actionitems" onClick={openInNewTab}>
                        <TfiWorld
                          tabIndex={0}
                          title={Constant.INTERNALAPP_CONSTANTS.LINK_TITLE}
                          className={`anchorLink ${userInfo.loggedInWeb && "disable-web-version"}`}
                        />
                      </div>
                    </div>
                    {!checkIfDeviceIsMob() &&
                      <div className="pdf-display-actionitemsDownload">
                        <div className="pdf-display-actionitems" onClick={handleDownload}>
                          <HiDownload className="datagrid_icon_mb" title={Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_TITLE} />
                        </div>
                      </div>}
                      {internalAppDetails?.contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES && (
                            <BsXLg
                              title="Close"
                              className="footNotes-close-btn"
                              onClick={() => handleBackButtonClick()}
                            />
                      )}
                  </div>
                </div>
                <div
                  className="contentDetailsViewPage contentDetailsViewPage-container"
                  dangerouslySetInnerHTML={{
                    __html: decodeString(selectedFile?.fileStream.replace('data:application/html;base64,', '').replace('data:application/htm;base64,', '').replace('data:application/octet-stream;base64, ', '').replace('data:text/html;base64, ', '')),
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      )}
      {
        <DialogBox
          closeOnOutsideClick={false}
          content={<Web_Version_Message />}
          headerName={
            <div className="dialogBoxHeader">
              <Image
                alt=""
                src={require("../../../../assets/EY_Logo.png")}
                fit="cover"
                id={"dialogBoxHeaderImg"}
              />
              <div>
                <strong className="dialogBoxHeaderTitle">
                {Constant.INTERNALAPP_CONSTANTS.DIALOGUE_TITLE_FOR_PDF}
                </strong>
              </div>
            </div>
          }
          headerActionName={{
            icon: <GrClose />,
            title: "Close",
            className: "dialogBoxCloseIcon",
            onClick: () => setWebVersionDisable(false),
          }}
          backdrop={true}
          openDialog={webVersionDisable}
          className={"dialogStyles"}
          cancelButton={"Close"}
          onCancelMethod={() => setWebVersionDisable(false)}
        />
      }
    </>
  );
}
