import React, { useState, useEffect } from "react";
import PdfDisplay from "../InternalApps/PdfPlayground/PdfDisplay/pdfDisplay";
import { useLocation } from "react-router-dom";
import { postData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import Constant from "../../utility/constants";
import PageNotFound from "../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import sideNavMainMenuData from "../../data/menu/sideNavMainMenuData";
import { useMenuContext } from "../../context/menucontext/menuContext";

const FootNotesLink = () => {
  const location = useLocation();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { userInfo } = UseUserInfoContext();
  const { handleChangeNav } = useMenuContext();

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState({
    isLoading: true,
    fileDetail: null,
    fileType: "",
    fileStream: "",
    browserUrl: "",
    attachment: "",
  });
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [internalAppDetails, setInternalAppDetails] = useState({
    contentTypeId: null,
    appID: null,
    breadCrumb: "",
    isGlobal: false,
    metaDataId: null,
    companyId: null,
    isInternal: false,
    appName: null,
    version: null,
  });

  const setBreadCrumbValue = () => {
    handleChangeNav({
      section: { id: sideNavMainMenuData[3]?.id, name: '' },
      menu: { id: sideNavMainMenuData[3]?.menu[0]?.id, name: 'Apps' },
      submenu: { id: sideNavMainMenuData[3]?.menu[0]?.submenu[0]?.id, name: internalAppDetails?.breadCrumb },
      data: '',
      to: '/apps/footnotesLink',
    });
  };

  const fetchData = async (appDetails) => {
    const { teams: { companyId: companyIdGlobal } = {} } = userInfo || {};
    const param = { metaDataId: appDetails.metaDataId, triggeredFrom: "edit" };

    setSelectedItem({ isLoading: true });
    setSelectedFile({ isLoading: true });

    try {
      const dataDetails = await postData(
        param,
        APIEndPoints.GET_DEEPLINK_CARDDETAILS_BY_ID(
          companyIdGlobal,
          appDetails.contentTypeId,
          appDetails.companyId
        )
      );
      const parsedData = JSON.parse(dataDetails.data.contentDetails);
      if (parsedData?.statusCode === "STCS001") {
        const resultData = parsedData?.resultData;
        setItems((prevData) => [...prevData, resultData]);
        setSelectedItem({ isLoading: false, item: resultData });

        if (resultData?.contentInfo?.[0]?.content?.fileDetail) {
          getFileDetail(resultData, appDetails);
        }
      } else if (dataDetails?.data?.statusCode === "STCS002") {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: Constant.ContentNotAvailable,
          type: "fail",
          isFailed: false,
        });
        setErrorApiCall(true);
      }
    } catch (error) {
      console.error("API call error:", error);
      setIsPdfLoading(false);
      setAccessDenied(error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied.");
    } finally {
      console.log("API call completed");
    }
  };

  const initializeAppDetails = async (params) => {
    setInternalAppDetails({
      contentTypeId: Number(params.contentType),
      appID: params.appId,
      breadCrumb: "Footnotes Analyzer",
      isGlobal: params.isGlobal,
      metaDataId: params.metaDataId,
      companyId: params.companyId,
      isInternal: true,
      appName: "FOOTNOTES",
      version: 2,
    });
  };

  useEffect(() => {
    const { companyId, metaDataId, contentType, appId, isGlobal } = location.state || {};
    const params = { companyId, metaDataId, contentType, appId, isGlobal };

    if (!companyId || !metaDataId || !contentType || !appId) {
      setErrorApiCall(true);
    } else if (isGlobal) {
      initializeAppDetails(params);
    } else {
      setAccessDenied(true);
    }
  }, [location.state]);

  useEffect(() => {
    console.log("internalAppDetails", internalAppDetails);
    if(internalAppDetails?.contentTypeId !== null){
      setBreadCrumbValue();
      fetchData(internalAppDetails);
    } 
  }, [internalAppDetails]);

  const getFileDetail = (item, appDetails) => {
    let file = item?.contentInfo?.[0]?.content;
    let attachments = item?.documentInfo?.[0]?.attachments?.documentInfo?.[0];
    let fileType = "";
    try {
      fileType =
        file?.fileDetail?.fileIdentifier.split(".").pop() === "htm"
          ? "html"
          : file?.fileDetail?.fileIdentifier.split(".").pop() === "html"
            ? "html"
            : file?.fileDetail?.fileIdentifier.split(".").pop() === "pdf"
              ? "pdf"
              : "";
    } catch { }

    setSelectedFile({
      isLoading: true,
      fileDetail: file?.fileDetail,
      fileType: fileType,
      fileStream: "",
      attachment: attachments
    });

    postData(
      {
        metaDataId: 0,
        appId: appDetails?.appID,
        contentType: appDetails?.contentTypeId,
        attachments: [file],
      },
      APIEndPoints.DOWNLOAD_ATTACHMENT_FOR_INTERNALAPPS(appDetails?.contentTypeId, appDetails?.companyId))
      .then((response) => {
        setIsPdfLoading(false);
        setSelectedFile((prevData) => ({
          ...prevData,
          isLoading: false,
          fileStream: response.data,
        }));
      })
      .catch((error) => {
        console.log(error);
        setIsPdfLoading(false);
        if (error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied.") {
          setAccessDenied(true);
        } else {
          setErrorApiCall(true);
        }
      });
  };

  if (errorApiCall) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;

  return (
    <PdfDisplay
      selectedFile={selectedFile}
      showComponent={true}
      onCloseClick={() => {}}
      internalAppDetails={internalAppDetails}
    />
  );
};

export default FootNotesLink;