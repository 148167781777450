import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TooltipHost } from "@fluentui/react";
import { Input, Button } from "@fluentui/react-northstar";
import { IoInformationCircle } from "react-icons/io5";
import { TbXboxX } from "react-icons/tb";
import { RxDragHandleDots2 } from "react-icons/rx";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  ConstrainMode,
  Selection,
  DetailsRow
} from "@fluentui/react/lib/DetailsList";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import { app } from "@microsoft/teams-js";

import Constant from "../../../utility/constants";
import DropdownComponent from "../../../commonui/commonformcomponents/dropdown/dropdown";
import MultiSelectReactDropdown from "../../../commonui/commonformcomponents/multiselectreactdropdown/MultiSelectReactDropdown";
import DatePicker from "../../../commonui/commonformcomponents/datepicker/datePicker";
import { SearchAndFilterColumnDynamic } from "../../../commonui/searchandfiltercolumndynamic/searchAndFilterColumnDynamic";
import { PanelView } from "../../../commonui/panelview/panelView";
import DialogBox from "../../../commonui/dialogBox/DialogBox";
import Label from "../../../commonui/commonformcomponents/label/Label";
import TextBox from "../../../commonui/commonformcomponents/textbox/TextBox";
import CheckboxComponent from "../../../commonui/commonformcomponents/checkbox/checkbox";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import BooleanExpressionEditor from "./booleanExpressionEditor";
import PowerBiReport from "../../../components/visualizations/pinlibrary/pinlibrarydetails/pbireport/pbiReport";
import { getData, postData } from "../../../services/customApis";
import APIEndPoints from "../../../utility/apiendpoints";
import {
  customFormatter,
  checkIfDeviceIsMob,
  downloadExcelFile,
  showLayover,
  hideLayover,
} from "../../../utility/utility";
import { utils } from "../../../commonui/datagrid/utils";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import { UseLibrarySearchFormContext } from "../../../context/librarysearchformcontext/librarySearchFormContext";
import { UseToasterNotificationsContext } from "../../../context/toasternotificationscontext/toasterNotificationsContext";
import PageNotFound from "../../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../../commonui/unauthorizedpage/unauthorized";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "./landingPage.css";

function LandingPage(props) {
  let internalAppDetails = props.state?.appDetails;
  let templateDetails = props.state?.templateDetails;
  let privilagesForPowerKApp = props.state?.privilagesPowerKApp;
  const navigate = useNavigate();
  let { userInfo } = UseUserInfoContext();
  const scrollDiv = useRef();
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const [gridData, setGridData] = useState(
    librarySearchFormJsonData?.powerKLibrarySearchGridData
  );
  const [panelOpen, setPanelOpen] = useState(false);
  const [currentParam, setCurrentParam] = useState({});
  const [specialCharacters, setSpecialCharacters] = React.useState();
  const [searchFormStepData, setSearchFormStepData] = useState({});
  const [booleanExpression, setBooleanExpression] = useState(
    librarySearchFormJsonData?.powerKBooleanExpression
  );
  const [hasDrodownUpdated, setHasDrodownUpdated] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [librarySearchForm, setLibrarySearchForm] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [columnsForSearchGrid, setColumnsForSearchGrid] = useState([]);
  const [masterGroupsSorted, setMasterGroupsSorted] = useState([]);
  const [masterOperators, setMasterOperators] = useState([]);
  const [draggedItem, setDraggedItem] = useState(gridData || undefined);
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const selection = useRef(new Selection()).current;
  const [currentItemToBeDeleted, setCurrentItemToBeDeleted] = useState({});
  const [loading, setLoading] = useState(false);
  const [pbReportConfig, setPBReportConfig] = useState({});
  const [openItems, setOpenItems] = useState(privilagesForPowerKApp?.librarySearch?.librarySearchEnabled ? ['1'] : []);
  const exportFilrNamesMapping = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]:
      Constant.INTERNALAPP_CONSTANTS.POWERK_LIBRARY_GRID_EXPORT_NAME,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]:
      Constant.INTERNALAPP_CONSTANTS.TPALERTS_LIBRARY_GRID_EXPORT_NAME,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]:
      Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_GRID_EXPORT_NAME,
  };
  const [executeData, setExecuteData] = useState(
    {
      searchQuery: librarySearchFormJsonData?.searchQuery,
    } ?? {}
  );
  const [searchFormPayload, setSearchFormPayload] = useState({
    stepData: [],
    booleanExpression: {
      expression: "",
    },
    customData: {
      stepsApplied: 0,
      totalSearchResults: librarySearchFormJsonData?.totalRecordsCount,
    },
  });
  const [validationMessage, setValidationMessage] = useState("");
  const inputRef = useRef(null);
  const previousBooleanExpression = useRef("");
  const selectionPoints = useRef({
    startingPoint: 0,
    endingPoint: 0,
  });
  const [fromToDatePicker, setFromToDatePicker] = useState([null, null]);
  const [isBackToSearch, setIsBackToSearch] = useState(true);
  const changedByExecute = useRef(false);
  const [defaultAction, setDefaultAction] = useState({});
  const [nonDefaultAction, setNonDefaultAction] = useState({});
  const [buttonContent, setButtonContent] = useState("");
  const [isAccordionDisabled, setIsAccordionDisabled] = useState(true);
  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [updateEditorValue, setUpdateEditorValue] = useState({
    updateEditor: true,
    inputSuccess: false,
  });
  const [updatedMasterGroups, setUpdatedMasterGroups] = useState([]);
  const [disableExecuteButton, setDisableExecuteButton] = useState(false);
  const [panelLoader, setPanelLoader] = useState(false);
  // const [appType, setAppType] = useState(1);
  // const [teamsAppId, setTeamsAppId] = useState(null);

  const [errorState, setErrorState] = useState({ Description: {} });
  
  useEffect(() => {
    if (props?.parentToChild) {
      setGridData([]);
      props?.setParentToChild(false);
    }
  }, [props?.parentToChild]);
  const isButtonDisabled = (param) => {
    let config = librarySearchForm?.searchPageConfig?.sections?.searchParams;
    if (
      config?.cascadeFlag == true &&
      config?.cascadeConfig?.parentGroups?.length > 0
    ) {
      let parent = librarySearchForm?.masterGroups?.filter((item) => {
        return (
          item?.groupType?.toLowerCase() ==
          Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_PARENT_GROUP_TYPE &&
          config?.cascadeConfig?.parentGroups?.find(
            (configItem) => configItem == item?.groupId
          )
        );
      });
      if (
        parent?.length > 0 &&
        param?.groupType ==
        Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_PARENT_GROUP_TYPE
      ) {
        if (gridData?.length > 0) {
          return gridData?.some((item) =>
            parent?.some(
              (parentItem) =>
                item?.searchParamsData?.groupId === parentItem?.groupId
            )
          )
            ? true
            : false;
        } else {
          return false;
        }
      } else {
        if (gridData?.length > 0) {
          return gridData?.some((item) =>
            parent?.some(
              (parentItem) =>
                item?.searchParamsData?.groupId === parentItem?.groupId
            )
          )
            ? false
            : true;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };
  const handleClearSelection = () => {
    if (gridData?.length > 0) {
      setGridData([]);
      setIsAccordionDisabled(true);
      setIsExportDisabled(true);
    }
  };
  const closeLoader = () => {
    setLoading(false);
  };

  const updateValidationState = (name, value, hasAnyError, errMessage) => {
    const updatedObj = {
      Description: {
        ...errorState.Description,
        controlName: name,
        currentValue: value,
        hasAnyError: hasAnyError,
        errorMessage: errMessage,
      },
    };
    setErrorState(updatedObj);
  };

  // Defined the helper funtion for resetting error state
  const resetErrorState = () => {
    setErrorState({ Description: {} });
  };

  useEffect(() => {
    if (
      searchFormStepData?.searchParamsData?.type ===
      Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE
    ) {
      let selectedValues = searchFormStepData?.userInputs?.selectedValues || [
        null,
        null,
      ];
      if (Array.isArray(selectedValues) && selectedValues.length > 0) {
        selectedValues = selectedValues.map((date) =>
          date ? new Date(date) : null
        );
      } else {
        selectedValues = [null, null];
      }
      setFromToDatePicker(selectedValues);
    }
  }, [searchFormStepData]);

  useEffect(() => {
    if (checkIfDeviceIsMob()) {
      const newUpdatedMasterGroups = [];
      const config =
        librarySearchForm?.searchPageConfig?.sections?.searchParams;
      if (masterGroupsSorted.length > 0) {
        masterGroupsSorted.forEach((group) => {
          if (
            config?.cascadeFlag === true &&
            config?.cascadeConfig?.parentGroups?.length > 0
          ) {
            const parent = masterGroupsSorted?.filter(
              (item) =>
                item?.groupType?.toLowerCase() ===
                Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_PARENT_GROUP_TYPE &&
                config?.cascadeConfig?.parentGroups?.find(
                  (configItem) => configItem === item?.groupId
                )
            );
            if (
              parent?.length > 0 &&
              group?.groupType ===
              Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_PARENT_GROUP_TYPE
            ) {
              if (gridData?.length > 0) {
                newUpdatedMasterGroups.push({
                  ...group,
                  disabled: gridData?.some((item) =>
                    parent?.some(
                      (parentItem) =>
                        item?.searchParamsData?.groupId === parentItem?.groupId
                    )
                  ),
                });
              } else {
                newUpdatedMasterGroups.push({ ...group, disabled: false });
              }
            } else {
              if (gridData?.length > 0) {
                newUpdatedMasterGroups.push({
                  ...group,
                  disabled: !gridData?.some((item) =>
                    parent?.some(
                      (parentItem) =>
                        item?.searchParamsData?.groupId === parentItem?.groupId
                    )
                  ),
                });
              } else {
                newUpdatedMasterGroups.push({ ...group, disabled: true });
              }
            }
          } else {
            return false;
          }
        });
      }
      setUpdatedMasterGroups(newUpdatedMasterGroups);
    }
  }, [masterGroupsSorted, gridData, librarySearchForm]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getAllowedChacter = () => {
      getData(APIEndPoints.GET_ALL_SPECIAL_CHARACTERS)
        .then((response) => {
          if (response.data) {
            setSpecialCharacters(response.data.textSpecialCharacters);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };
    const getLibrarySearchFormJsonData = async () => {
      const params = internalAppDetails
        ? {
          isInternalApp: internalAppDetails.isInternal,
          appId: internalAppDetails.appID,
          customData: {},
          templateId: templateDetails?.templateId?.toString(),
        }
        : {
          isInternal: false,
          appId: null,
          customData: {},
          templateId: templateDetails?.templateId?.toString(),
        };
      try {
        const jsonTemplateData = await postData(
          params,
          APIEndPoints.GET_SEARCHTEMPLATE_JSON_DETAILS(
            internalAppDetails?.contentTypeId,
            userInfo?.teams?.companyId
          )
        );
        handleAttachmentDownload(jsonTemplateData?.data?.resultData);
      } catch (error) {
        setIsLoading(false);
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied."
        ) {
          setAccessDenied(true);
        } else {
          setErrorApiCall(true);
        }
      }
    };
    getAllowedChacter();
    getLibrarySearchFormJsonData();
    setButtonContent(defaultAction?.displayName);
  }, []);

  const resetValidationMessage = () => {
    if (disableExecuteButton) {
      setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.PARENTHESES_COUNT_NOT_MATCHING);
    }
    else {
      setValidationMessage("");
    }
  }

  useEffect(() => {
    if (changedByExecute.current || isBackToSearch) {
      setIsBackToSearch(false);
    } else if (gridData?.length > 0) {
      let allIsSelectedSteps = gridData.filter(
        (item) => item?.uiConfigs?.isSelected === true
      );
      if (allIsSelectedSteps.length > 0) {
        let updatedBooleanExpression = allIsSelectedSteps
          .map((item) => {
            if (
              item?.uiConfigs?.reOrderIndex === 1 &&
              item?.userInputs?.selectedOperators?.length > 0 &&
              item?.userInputs?.selectedOperators[0]?.toLowerCase() !== "not"
            ) {
              return `${item["stepId"]}`;
            } else {
              return item?.userInputs?.selectedOperators?.length > 0
                ? `${item?.userInputs["selectedOperators"][0]} ${item["stepId"]}`
                : `${item["stepId"]}`;
            }
          })
          .join(" ");

        //comparing the previous boolean expression and newly generated expression, if there is change then boolean parameter is updated
        let trimmedUpdatedBooleanExpressionArray = updatedBooleanExpression
          .replace(/ /g, "")
          .match(/[0-9a-zA-Z]+/g);
        let trimmedUpdatedBooleanExpression =
          trimmedUpdatedBooleanExpressionArray?.length > 1
            ? trimmedUpdatedBooleanExpressionArray.join("")?.toLowerCase()
            : trimmedUpdatedBooleanExpressionArray?.length > 0
              ? trimmedUpdatedBooleanExpressionArray[0]?.toLowerCase()
              : trimmedUpdatedBooleanExpressionArray?.toLowerCase() ?? "";
        let trimmedBooleanExpressionArray = booleanExpression
          .replace(/ /g, "")
          .match(/[0-9a-zA-Z]+/g);
        let trimmedBooleanExpression =
          trimmedBooleanExpressionArray?.length > 1
            ? trimmedBooleanExpressionArray.join("")?.toLowerCase()
            : trimmedBooleanExpressionArray?.length > 0
              ? trimmedBooleanExpressionArray[0]?.toLowerCase()
              : trimmedBooleanExpressionArray?.toLowerCase() ?? "";
        if (trimmedUpdatedBooleanExpression !== trimmedBooleanExpression) {
          setBooleanExpression(`${updatedBooleanExpression.toUpperCase()}`);
          setUpdateEditorValue({ updateEditor: true, inputSuccess: false });
          setValidationMessage("");
        } 
        else {
          resetValidationMessage();
        }
      } else {
        setBooleanExpression("");
        setUpdateEditorValue({ updateEditor: true, inputSuccess: false });
        setValidationMessage("");
      }
    } else {
      setBooleanExpression("");
      setUpdateEditorValue({ updateEditor: true, inputSuccess: false });
      setValidationMessage("");
    }
    if (changedByExecute.current || isBackToSearch) {
      changedByExecute.current = false;
    } else {
      setSearchFormPayload((prevValue) => ({
        ...prevValue,
        customData: { ...prevValue.customData, totalSearchResults: "" },
      }));
    }
    handleLibrarySearchFormJsonData({
      powerKLibrarySearchGridData: gridData,
    });
  }, [gridData]);

  const handleExecuteClick = (e) => {
    if (
      e.target?.innerText?.toLowerCase() ===
      defaultAction?.displayName?.toLowerCase()
    ) {
      setButtonContent(nonDefaultAction?.displayName);
      execute();
    } else {
      viewSearchResults();
    }
  };

  const deleteStep = (item) => {
    const checkRemainingSteps = gridData.filter(
      (items) => items?.stepId > item?.stepId
    );
    let remainingSelectedSteps;
    let nextEligibleFirstStep = {};
    if (item?.uiConfigs?.reOrderIndex === 1) {
      remainingSelectedSteps = gridData.filter(
        (filterItem) =>
          filterItem?.uiConfigs?.isSelected && filterItem?.stepId > item?.stepId
      );
      if (remainingSelectedSteps.length > 0) {
        nextEligibleFirstStep = remainingSelectedSteps[0];
      }
    }
    const initialLength = gridData?.length;
    let removeSelectedStep = gridData.filter(
      (items) => items?.stepId !== item?.stepId
    );
    if (checkRemainingSteps?.length > 0) {
      const updatedTheStepIds = removeSelectedStep.map((currentItem) => {
        if (currentItem?.stepId > item?.stepId) {
          if (
            item?.uiConfigs?.reOrderIndex === 1 &&
            currentItem?.stepId === nextEligibleFirstStep?.stepId
          ) {
            return {
              ...currentItem,
              stepId: currentItem?.stepId - 1,
              userInputs: {
                ...currentItem.userInputs,
                selectedOperators:
                  currentItem?.userInputs?.selectedOperators[0]?.toLowerCase() ===
                    "not"
                    ? currentItem?.userInputs?.selectedOperators
                    : currentItem?.stepId - 1 == 1
                      ? []
                      : currentItem?.userInputs?.selectedOperators,
              },
              uiConfigs: { ...currentItem.uiConfigs, reOrderIndex: 1 },
            };
          }
          return { ...currentItem, stepId: currentItem?.stepId - 1 };
        }
        return currentItem;
      });
      setGridData(updatedTheStepIds);
    } else {
      setGridData(removeSelectedStep);
    }
    setCurrentItemToBeDeleted({});
    if (removeSelectedStep.length < initialLength) {
      setButtonContent(defaultAction?.displayName);
    }
  };

  useEffect(() => {
    const trimmedPrevious = previousBooleanExpression.current.replace(
      /\s/g,
      ""
    );
    const trimmedCurrent = booleanExpression.replace(/\s/g, "");

    if (
      trimmedPrevious === trimmedCurrent &&
      buttonContent === nonDefaultAction?.displayName
    ) {
      setButtonContent(nonDefaultAction?.displayName);
    } else {
      setButtonContent(defaultAction?.displayName);
    }

    if (validationMessage?.length < 2) {
      let openParenthesisCount = booleanExpression.match(/\(/g)
        ? booleanExpression.match(/\(/g).length
        : 0;
      let closedParenthesisCount = booleanExpression.match(/\)/g)
        ? booleanExpression.match(/\)/g).length
        : 0;
      if (openParenthesisCount !== closedParenthesisCount) {
        setValidationMessage(
          Constant.LANDING_PAGE_CONSTANTS.PARENTHESES_COUNT_NOT_MATCHING
        );
        setDisableExecuteButton(true);
      } else {
        setDisableExecuteButton(false);
      }
    }
    previousBooleanExpression.current = booleanExpression;
    handleLibrarySearchFormJsonData({
      powerKBooleanExpression: booleanExpression,
    });
  }, [booleanExpression]);
  const handleAttachmentDownload = async (fileDetails) => {
    try {
      const response = await postData(
        {
          metaDataId: 0,
          contentType: internalAppDetails.contentTypeId,
          attachments: [fileDetails],
          appId: internalAppDetails.appID,
        },
        APIEndPoints.DOWNLOAD_ATTACHMENT_FOR_INTERNALAPPS(
          internalAppDetails.contentTypeId,
          userInfo?.teams?.companyId
        )
      );

      if (response?.status === false) {
        setIsLoading(false);
        throw new Error("Download failed");
      } else if (response?.data !== null) {
        const base64Data = response.data
          .replace("data:application/json;base64,", "")
          .replace("data:application/octet-stream;base64, ", "");
        const decodedString = atob(base64Data);
        try {
          const jsonObject = JSON.parse(decodedString);
          setLibrarySearchForm(jsonObject);
          setMasterGroupsSorted(
            jsonObject?.masterGroups?.sort(
              (a, b) => a?.displayOrder - b.displayOrder
            )
          );
          let accordions =
            jsonObject?.searchPageConfig?.accordionConfig?.accordions?.sort(
              (a, b) => a?.id - b.id
            );
          let footerButtonsText;
          if (
            jsonObject?.searchPageConfig?.type ===
            Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_ACCORDION
          ) {
            footerButtonsText = accordions?.[0]?.buttons[0]?.actions;
          } else {
            footerButtonsText =
              jsonObject?.searchPageConfig?.sections?.footer?.actions;
          }
          const sortedFooterButtons = footerButtonsText?.sort(
            (a, b) => a?.id - b?.id
          );
          let defaultActionBtn =
            sortedFooterButtons?.length > 0 &&
            sortedFooterButtons?.filter((action) => action?.isDefault);
          setDefaultAction(
            sortedFooterButtons?.length > 0 &&
            sortedFooterButtons?.find((action) => action?.isDefault)
          );
          setButtonContent(
            defaultActionBtn?.length > 0 && defaultActionBtn?.[0]?.displayName
          );
          setNonDefaultAction(
            sortedFooterButtons?.length > 0 &&
            sortedFooterButtons?.find((action) => !action?.isDefault)
          );
          setMasterOperators(
            jsonObject?.masterOperators?.sort(
              (a, b) => a?.displayOrder - b.displayOrder
            )
          );
          const operatorColumns =
            jsonObject?.masterOperators?.length > 0 &&
            jsonObject?.masterOperators.map((operator) =>
              createColumn(
                operator?.operatorName?.toLowerCase(),
                operator?.operatorName,
                operator?.operatorName?.toLowerCase(),
                30,
                30,
                false,
                "checkbox"
              )
            );
          let columnHeaders =
            jsonObject?.searchPageConfig?.sections?.steps?.columns?.map(
              (item) =>
                createColumn(
                  item?.key,
                  item?.name,
                  item?.displayName,
                  item?.minWidth,
                  item?.maxWidth,
                  item?.isResizable,
                  item?.type,
                  item?.className
                )
            );
          const updatedColumnHeaders = [];
          columnHeaders.forEach((item) => {
            if (item?.name === "Operators") {
              updatedColumnHeaders.push(...operatorColumns);
            } else {
              updatedColumnHeaders.push(item);
            }
          });
          setColumnsForSearchGrid(updatedColumnHeaders);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error("Failed to parse JSON:", error);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to download attachment:", error);
    }
  };

  const closePanel = () => {
    setPanelOpen(false);
    setSelectedSection("");
    resetValidationMessage();
    resetErrorState();
  };

  const openPanel = (param, isEdit) => {
    setPanelOpen(true);
    let currentGroup = librarySearchForm?.searchParams
      ?.filter(
        (searchParam) =>
          searchParam.groupId === param?.groupId ||
          searchParam.groupId === param?.searchParamsData?.groupId
      )
      ?.sort((a, b) => a?.displayOrder - b.displayOrder);

    setCurrentParam({
      ...currentParam,
      groupDetails: param,
      groupedParams: currentGroup,
      isEdit: isEdit ?? false,
    });
    if (param?.userInputs?.selectedValues?.length > 0) {
      setSearchFormStepData(param);
    } else {
      setSearchFormStepData({});
    }
    resetValidationMessage();
  };

  const handleSelectedValues = (selectedValues) => {
    if (selectedValues.length === 2) {
      if (!selectedValues[0] && selectedValues[1]) {
        selectedValues[0] = selectedValues[1];
      } else if (selectedValues[0] && !selectedValues[1]) {
        selectedValues[1] = selectedValues[0];
      }
      const hasDuplicates = utils.hasDuplicates(selectedValues);
      if (hasDuplicates) {
        selectedValues = selectedValues.map((date) => {
          return date ? customFormatter(new Date(date)) : null;
        });
      }
    }
    return selectedValues;
  };

  const addGridData = (isEdit) => {
    let gridDataArr = [...gridData];
    const initialLength = gridData?.length;
    if (isEdit) {
      const existingIndex =
        gridData &&
        gridData?.findIndex(
          (item) => item.stepId === searchFormStepData?.stepId
        );
      if (existingIndex !== -1) {
        if (
          searchFormStepData?.searchParamsData?.type ===
          Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE &&
          searchFormStepData?.userInputs?.selectedValues?.length > 0
        ) {
          searchFormStepData.userInputs.selectedValues = handleSelectedValues(
            searchFormStepData.userInputs.selectedValues
          );
        }
        let existingIndexSelectedValues = gridDataArr[existingIndex];
        if (
          JSON.stringify(existingIndexSelectedValues) !==
          JSON.stringify(searchFormStepData)
        ) {
          setButtonContent(defaultAction?.displayName);
        }
        gridDataArr[existingIndex] = searchFormStepData;
      } else {
        gridDataArr.push(searchFormStepData);
      }
      setGridData(gridDataArr);
    } else {
      if (
        searchFormStepData?.searchParamsData?.type ===
        Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE &&
        searchFormStepData?.userInputs?.selectedValues?.length > 0
      ) {
        searchFormStepData.userInputs.selectedValues = handleSelectedValues(
          searchFormStepData.userInputs.selectedValues
        );
      }
      gridDataArr.push(searchFormStepData);
      setGridData(gridDataArr);
      if (gridDataArr?.length > initialLength) {
        setButtonContent(defaultAction?.displayName);
      }
    }
    setSearchFormStepData({});
    setPanelOpen(false);
    setSelectedSection("");
  };

  const showNotification = (msg, type) => {
    handleNotificationMsgsData({
      showMsgBar: true,
      started: false,
      completed: true,
      msg,
      type,
      isFailed: false,
    });
  };
  const getTeamsAppId = async (userInfo) => {
    if (userInfo?.loggedInWeb) {
      return null;
    }
    try {
      const { user } = await app.getContext();
      const email = user?.userPrincipalName ?? userInfo?.email;
      return Constant.GetUserAppId(email);
    } catch (error) {
      console.error('Error fetching Teams App ID:', error);
      return null;
    }
  };
  const exportFootnoteReports = async () => {
    showNotification(Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_INIT_MSG, "Info");
    const tempId = templateDetails?.templateId;
    // for footnotes, metaDataIds is empty or blank array
    const metaDataIds = [];
    const { appID, contentTypeId } = internalAppDetails || {};
    const params = {
      metaDataIds,
      appId: appID,
      templateId: tempId,
      searchId: "",
      ...(contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES && {
        customData: JSON.stringify({
          companyId: userInfo?.teams?.companyId,
          appType: userInfo?.loggedInWeb ? 1 : 2,
          teamsAppId: await getTeamsAppId(userInfo),
        }),
      }),
    };
    if (
      internalAppDetails?.contentTypeId ==
      Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
    ) {
      params.searchId = executeData?.searchQuery ?? "";
    }
    try {
      const response = await postData(
        params,
        APIEndPoints.DOWNLOAD_EXCEL_REPORT_INTERNAL_APPS(internalAppDetails?.contentTypeId)
      );

      if (response.status !== 200) {
        showNotification(
          Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG,
          "Fail"
        );
        return;
      }
      const resultData = response?.data?.resultData;
      if (resultData && typeof resultData === "object") {
        const inputFileName = exportFilrNamesMapping[internalAppDetails?.contentTypeId] || "";
        const isDownload = downloadExcelFile(resultData, inputFileName);
        const message = isDownload
          ? Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_SUCCESS_FOOTNOTE_MSG
          : Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG;
        const type = isDownload ? "Success" : "Fail";
        showNotification(message, type);
      } else {
        showNotification(
          Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG,
          "Fail"
        );
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error?.response?.data?.Errors?.[0]?.toLowerCase();
      if (errorMessage === "access denied.") {
        setAccessDenied(true);
      } else {
        const message =
          errorMessage || Constant.INTERNALAPP_CONSTANTS.DOWNLOAD_FAILED_MSG;
        showNotification(message, "Fail");
      }
    }
  };
  const handleDateConversion = (fromToDatePicker, nonNullIndex) => {
    if (typeof fromToDatePicker[nonNullIndex] === 'string') {
      fromToDatePicker[nonNullIndex] = utils.convertStringToDate(fromToDatePicker[nonNullIndex]);
    }
  };

  const renderControl = (controlObj) => {
    if (controlObj != undefined) {
      switch (controlObj?.type) {
        case "text":
          return (
            <>
              <Label content={controlObj?.displayName} />
              <TextBox
                placeholder={controlObj.placeholder}
                required={controlObj?.isRequired}
                onChange={(e,name,controlName,cf,cof,pn,hasError,errMessage) => {
                  controlsChangeHandler(e,controlObj,name,controlName,cf,cof,pn,hasError,errMessage,);
                }}
                additionalMessage={controlObj?.additionalMessage}
                value={searchFormStepData?.userInputs?.selectedValues}
                name={controlObj?.displayName}
                id={controlObj?.paramUniqueId}
                disabled={false}
                isLibrarySearch={true}
                validations={[
                  {
                    name: "AllowedSpecialCharsForInternalApp",
                    allowedSpecialChars: specialCharacters,
                    errorMessage:
                      Constant.INTERNALAPP_CONSTANTS
                        .SPECIAL_CHARACTER_ERROR_MESSAGE,
                  },
                ]}
                hasAnyError={errorState?.[controlObj?.displayName]?.hasAnyError}
                errMessage={errorState?.[controlObj?.displayName]?.errorMessage}
              />
            </>
          );
        case "datePicker":
          let val = searchFormStepData?.userInputs?.selectedValues;
          if (val != null && val != undefined && val != "") {
            val = new Date(val);
          }
          return (
            <>
              <Label content={controlObj?.displayName} />
              <DatePicker
                onChangeHandler={(e) => {
                  controlsChangeHandler(e, controlObj);
                }}
                allowManualInput={true}
                inputPlaceholder="mm-dd-yyyy"
                clearable={true}
                name={controlObj?.displayName}
                placeholder={controlObj.placeholder}
                value={val}
                id={controlObj?.paramsId}
                disabled={false}
                isLibrarySearch={true}
              />
            </>
          );
        case "fromToDatePicker":
          let fromToDatePicker = searchFormStepData?.userInputs
            ?.selectedValues || [null, null];
            if (
              Array.isArray(fromToDatePicker) &&
              fromToDatePicker?.length > 0 &&
              fromToDatePicker?.every(item => item !== null)
            ) {
            fromToDatePicker = fromToDatePicker.map((date) =>
              date ? utils.convertStringToDate(date) : null
            );
            console.log(fromToDatePicker);
          }
          else if (
            Array.isArray(fromToDatePicker) &&
            fromToDatePicker?.length > 0 &&
            (fromToDatePicker?.filter(item => item !== null).length > 0)
          ) {
            const nonNullIndex = fromToDatePicker.findIndex(item => item !== null);
            if (nonNullIndex !== -1) {
              handleDateConversion(fromToDatePicker, nonNullIndex);
            }
          }
          return (
            <div className="fromToDatePickersContainer">
              {controlObj?.typeConfig?.length > 0 &&
                controlObj.typeConfig.map((config, index) => (
                  <div
                    key={index}
                    className={
                      index === 0
                        ? "fromToDatePickerStart"
                        : "fromToDatePickerEnd"
                    }
                  >
                    <Label content={config.displayName} />
                    <DatePicker
                      onChangeHandler={(e) => {
                        const newDates = [...fromToDatePicker];
                        newDates[index] = e;
                        controlsChangeHandler(newDates, controlObj);
                      }}
                      allowManualInput={true}
                      inputPlaceholder="mm-dd-yyyy"
                      clearable={true}
                      name={config.displayName}
                      placeholder={config.placeholder}
                      value={fromToDatePicker[index]}
                      id={config.paramsId}
                      disabled={false}
                      isLibrarySearch={true}
                      maxDate={
                        index == 0 &&
                          fromToDatePicker[1]?.toString()?.length > 0
                          ? fromToDatePicker[1]
                          : null
                      }
                      minDate={
                        index == 1 &&
                          fromToDatePicker[0]?.toString()?.length > 0
                          ? fromToDatePicker[0]
                          : null
                      }
                    />
                  </div>
                ))}
            </div>
          );

        case "dropdown":
          if (controlObj?.isMultiSelect == true) {
            if (controlObj?.isSearchable) {
              return (
                <>
                  <Label content={controlObj?.displayName} />
                  <SearchAndFilterColumnDynamic
                    appDetails={internalAppDetails}
                    appSearchQueryValue={""}
                    fieldName={controlObj?.paramUniqueId}
                    itemsSelected={
                      searchFormStepData?.userInputs?.selectedValues || []
                    }
                    columnFilteringUpdateMethod={(
                      controlObj,
                      searchOptionID,
                      eachSection,
                      eachRow,
                      selectedValues
                    ) => {
                      columnFilteringMethodSearchAndFilter(
                        controlObj,
                        searchOptionID,
                        eachSection,
                        eachRow,
                        selectedValues
                      );
                    }}
                    isFromSearch={true}
                    controlParams={{
                      controlObj: controlObj,
                    }}
                    templateDetails={templateDetails}
                    specialCharacters={specialCharacters}
                    hasDrodownUpdated={hasDrodownUpdated}
                    setHasDrodownUpdated={setHasDrodownUpdated}
                    placeHolderText={controlObj?.placeHolderText ?? ""}
                    setPanelLoading ={setPanelLoader}
                  />
                </>
              );
            } else {
              return (
                <>
                  <Label content={controlObj?.displayName} />
                  <MultiSelectReactDropdown
                    options={controlObj?.valueObject?.data}
                    showCheckbox={true}
                    selectedValues={searchFormStepData || []}
                    emptyRecordMsg={controlObj.EmptyMsg}
                    placeholder={controlObj.placeHolderText}
                    displayValue={"value"}
                    customCloseIcon={true}
                    onSelect={(selectedList) => {
                      controlsChangeHandler(selectedList, controlObj);
                    }}
                    onRemove={(selectedList) => {
                      controlsChangeHandler(selectedList, controlObj);
                    }}
                    name={controlObj?.controlID}
                    closeOnSelect={true}
                    showArrow={true}
                    hideSelectedList={false}
                    isDragDisabled={true}
                    defaultCheckedValue={""}
                    id={controlObj?.controlID}
                    isLibrarySearch={true}
                  />
                </>
              );
            }
          } else {
            return (
              <>
                <Label content={controlObj?.displayName} />
                <DropdownComponent
                  items={controlObj?.data}
                  fieldsName={controlObj?.fieldsName}
                  isCustomField={controlObj?.isCustomData}
                  onDropDownChange={(e) => {
                    controlsChangeHandler(e, controlObj);
                  }}
                  defaultCheckedValue={""}
                  placeholder={controlObj?.placeHolderText}
                  search={controlObj?.isSearchable}
                  value={searchFormStepData?.userInputs?.selectedValues || ""}
                  checkable={controlObj?.isCheckable}
                  id={controlObj?.paramsId}
                  disabled={false}
                  multiple={controlObj?.isMultiSelect}
                  initialLoad={true}
                  page={"LibrarySearchForm"}
                  isLibrarySearch={true}
                />
              </>
            );
          }
        default:
      }
    }
  };

  const createColumn = (
    key,
    name,
    fieldName,
    minWidth,
    maxWidth,
    isResizable,
    type = null,
    className
  ) => ({
    key,
    name,
    fieldName,
    ariaLabel: name?.toLowerCase() === Constant.LANDING_PAGE_CONSTANTS.SEARCH_RESULTS_NAME?.toLowerCase() ?
      Constant.LANDING_PAGE_CONSTANTS.SEARCH_RESULTS_TOOLTIP :
      name?.toLowerCase() === Constant.LANDING_PAGE_CONSTANTS.STEP_RESULTS_NAME?.toLowerCase() ?
        Constant.LANDING_PAGE_CONSTANTS.STEP_RESULTS_TOOLTIP : name,
    minWidth: window.innerWidth >= 900 ? minWidth : maxWidth,
    maxWidth: window.innerWidth >= 900 ? minWidth : maxWidth,
    isResizable: isResizable,
    isMultilineEnable: true,
    type,
    className,
  });

  const handleCheckboxChange = (
    e,
    value,
    fN,
    isCF,
    name,
    hE,
    eM,
    index,
    option
  ) => {
    let gridDataArr = [...gridData];
    let selReOrderIndexRow = gridDataArr?.filter(
      (item) => item.uiConfigs.reOrderIndex == 1
    );

    let firstIsSelectedRowIndexNumber = gridDataArr?.findIndex(
      (item) => item.uiConfigs.reOrderIndex === 1 ?? -1
    );
    let remainingSelectedSteps = gridDataArr.filter(
      (item) => item?.uiConfigs?.isSelected && item?.stepId > index + 1
    );
    let nextEligibleFirstStep = {};

    if (value?.checked === false) {
      if (gridDataArr[index].uiConfigs.reOrderIndex === 1) {
        if (remainingSelectedSteps.length > 0) {
          nextEligibleFirstStep = remainingSelectedSteps[0];
          gridDataArr[
            nextEligibleFirstStep.stepId - 1
          ].uiConfigs.reOrderIndex = 1;
        }
        gridDataArr[index].uiConfigs = {
          isSelected: value?.checked,
          reOrderIndex: 0,
        };
      } else {
        gridDataArr[index].uiConfigs.isSelected = value?.checked;
      }
    } else {
      if (index < firstIsSelectedRowIndexNumber) {
        gridDataArr[index].uiConfigs = {
          isSelected: value?.checked,
          reOrderIndex: 1,
        };
        gridDataArr[firstIsSelectedRowIndexNumber].uiConfigs.reOrderIndex =
          gridDataArr[firstIsSelectedRowIndexNumber].stepId;
        if (
          gridDataArr[firstIsSelectedRowIndexNumber].userInputs
            .selectedOperators.length < 1
        )
          gridDataArr[
            firstIsSelectedRowIndexNumber
          ].userInputs.selectedOperators = ["AND"];
      } else if (remainingSelectedSteps.length > 0) {
        gridDataArr[index].uiConfigs.isSelected = value?.checked;
      } else if (firstIsSelectedRowIndexNumber === -1) {
        gridDataArr[index].uiConfigs = {
          isSelected: value?.checked,
          reOrderIndex: 1,
        };
      } else if (firstIsSelectedRowIndexNumber < index) {
        gridDataArr[index].uiConfigs.isSelected = value?.checked;
      } else {
        gridDataArr[index].uiConfigs = {
          isSelected: value?.checked,
          reOrderIndex: 1,
        };
      }
    }
    setGridData(gridDataArr);
    setButtonContent(defaultAction?.displayName);
  };

  const createStep = (id, searchParamData, value) => {
    const ExistingSelectedSteps = gridData.filter(
      (item) => item?.uiConfigs?.isSelected === true
    );
    return {
      stepId: id,
      searchParamsData: searchParamData,
      uiConfigs: {
        reOrderIndex: ExistingSelectedSteps?.length > 0 ? id : 1,
        isSelected: true,
      },
      userInputs: {
        selectedValues: value,
        selectedOperators: id === 1 ? [] : ["AND"],
      },
      customData: {
        stepCount: "",
        casCadedStepCount: "",
      },
    };
  };

  const controlsChangeHandler = (values,controlObj,name,controlName,cf,cof,pn,hasError,errMessage,
  ) => {
    let stepData = {};
    let value;
    if (controlObj?.isMultiSelect && values?.length < 1) {
      value = [];
    } else {
      value = getValueBasedOnFieldType(controlObj, values);
    }
    let searchFormControlDataObj = { ...searchFormStepData };
    if (Object.keys(searchFormControlDataObj)?.length > 0) {
      setSearchFormStepData({
        ...searchFormStepData,
        userInputs: { ...searchFormStepData.userInputs, selectedValues: value },
      });
    } else {
      stepData = createStep(gridData?.length + 1, controlObj, value);
      setSearchFormStepData(stepData);
    }
    if (controlObj?.type === "text" && value?.length > 0 && value?.trim() !== "") {
      updateValidationState(controlName, value, hasError, errMessage);
    }
  };

  const updateStepData = (arr, textBoxControlDisabled) => {
    setHasDrodownUpdated(true);
    if(textBoxControlDisabled)
    {
      resetErrorState();
    }
    let updatedStep = createStep(
      gridData?.length + 1,
      arr,
      arr?.selectedValueType == "array" ? [] : ""
    );
    setSearchFormStepData(() => {
      return updatedStep;
    });
  };

  const getValueBasedOnFieldType = (controlObj, value) => {
    switch (controlObj?.type) {
      case "text":
        return value.target.value;
      case "textarea":
        return value.target.value;
      case "datePicker":
        return customFormatter(new Date(value));
      case "fromToDatePicker":
        value =
          value?.length > 0 &&
          value.map((date) => {
            date = date ? customFormatter(new Date(date)) : null;
            return date;
          });
        return value;
      case "dropdown":
        if (controlObj?.isMultiSelect == true) {
          return value?.map((element) => element.name);
        } else {
          return value?.content;
        }
      default:
        console.log("No control found");
    }
  };

  const columnFilteringMethodSearchAndFilter = (
    controlObj,
    searchOptionID,
    eachSection,
    eachRow,
    selectedValues
  ) => {
    // if (selectedValues?.length !== 0) {
    controlsChangeHandler(selectedValues, controlObj);
    // }
  };
  const findValueByKey = (obj, keyToFind) => {
    if (obj.hasOwnProperty(keyToFind)) {
      return obj[keyToFind];
    }
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const result = findValueByKey(obj[key], keyToFind);
        if (result !== undefined) {
          return result;
        }
      }
    }
    return undefined;
  };
  const execute = async () => {
    setValidationMessage("");
    setIsGridLoading(true);
    let booleanExpWOBlankArray = booleanExpression
      .replace(/ /g, "")
      .match(/[()0-9a-zA-Z]+/g);
    let booleanExpWOBlank =
      booleanExpWOBlankArray?.length > 1
        ? booleanExpWOBlankArray.join("")
        : booleanExpWOBlankArray?.length > 0
          ? booleanExpWOBlankArray[0]
          : booleanExpWOBlankArray ?? "";
    showLayover();
    const formData = new FormData();
    let params = {
      isInternal: true,
      pageLength:
        Constant.INTERNAL_APPS_GENERAL_CONSTANTS.FIELD_LEVEL_PAGE_SIZE,
      pageNumber: 1,
      version: internalAppDetails?.version ?? 0,
      sortBy: {
        type: "",
        order: "",
      },
      filters: {
        fileDetail: {
          actualFileName: "librarySearchForm.json",
        },
        fields: "",
      },
    };
    if (executeData?.searchQuery)
      params["searchQuery"] = executeData?.searchQuery;
    let payload = {};
    if (internalAppDetails) {
      formData.append("RequestJson", JSON.stringify(params));
      formData.append("AppId", internalAppDetails?.appID);
      formData.append("isInternalApp", internalAppDetails?.isInternal);
      formData.append(
        "TemplateId",
        templateDetails?.templateId?.toString() ?? ""
      );
      let gridDataArrString = JSON.stringify([...gridData]);
      let gridDataArr = JSON.parse(gridDataArrString);
      let newGridDataArr = [];
      if (gridDataArr?.length > 0) {
        if (gridDataArr[0]["userInputs"]["selectedOperators"]?.length == 0)
          gridDataArr[0]["userInputs"]["selectedOperators"] = ["AND"];
        newGridDataArr = gridDataArr.map((item) => {
          if (item.userInputs.selectedOperators?.length > 0) {
            item.userInputs.selectedOperators =
              item?.userInputs?.selectedOperators.map((operator) => {
                return operator?.toUpperCase();
              });
          }
          if (
            item.searchParamsData.type?.toLowerCase() ===
            Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE?.toLowerCase()
          ) {
            item.userInputs.selectedValues = {
              from: item?.userInputs?.selectedValues[0] ?? "",
              to: item?.userInputs?.selectedValues[1] ?? "",
            };
          }
          return item;
        });
      }
      payload = {
        stepData: newGridDataArr?.filter(
          (item) => item?.uiConfigs?.isSelected === true
        ),
        booleanExpression: {
          expression: booleanExpWOBlank,
        },
        customData: {
          stepsApplied:
            newGridDataArr?.filter(
              (item) => item?.uiConfigs?.isSelected === true
            )?.length ?? 0,
          totalSearchResults:
            searchFormPayload?.customData?.totalSearchResults ?? 0,
        },
        keywordFields: librarySearchForm?.keywordFields,
      };
      const jsonStr = JSON.stringify(payload);
      const file = new Blob([jsonStr], { type: "application/json" });
      formData.append("Attachments", file, "librarySearchForm.json");
    }
    try {
      const powerKDBData = await postData(
        formData,
        APIEndPoints.GET_FILTERED_DATA_LIBRARY_META_DATA(
          internalAppDetails?.contentTypeId,
          userInfo?.teams?.companyId
        ),
        true
      );
      if (powerKDBData?.data?.[0]?.status == "failed") {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg:
            powerKDBData?.data?.[0]?.statusMessage ??
            Constant.SOMETHING_WENTWRONG,
          type: "fail",
          isFailed: false,
        });
        setButtonContent(defaultAction?.displayName);
      } else {
        let searchResults = powerKDBData?.data?.[0]?.resultData?.filterDetails;
        if (searchResults?.length > 0) {
          let results = searchResults?.filter((item) => item?.stepId == 0)?.[0];
          setButtonContent(nonDefaultAction?.displayName);
          let gridDataCopy = [...gridData];
          searchResults?.length > 0 &&
            searchResults.map((item) => {
              let eachStep = gridDataCopy?.filter(
                (gridItem) => gridItem?.stepId === item?.stepId
              )?.[0];
              if (eachStep) {
                eachStep["customData"]["stepCount"] = item?.count;
                eachStep["customData"]["casCadedStepCount"] =
                  item?.cascadeCount;
              }
            });
          setGridData(gridDataCopy);
          setExecuteData(powerKDBData?.data?.[0]?.resultData);
          handleLibrarySearchFormJsonData({
            searchQuery: powerKDBData?.data?.[0]?.resultData?.searchQuery ?? "",
            totalRecordsCount: results?.cascadeCount ?? 0,
            powerKLibrarySearchGridData: gridDataCopy,
          });
          searchResults?.length > 0 &&
            setSearchFormPayload({
              ...payload,
              customData: {
                ...payload.customData,
                totalSearchResults: results?.cascadeCount ?? 0,
              },
            });
          changedByExecute.current = true;
        }
      }
      setIsGridLoading(false);
      hideLayover();
    } catch (error) {
      console.error(error);
      setIsGridLoading(false);
      hideLayover();
      handleNotificationMsgsData({
        showMsgBar: true,
        started: false,
        completed: true,
        msg: error?.response?.data?.Errors ?? Constant.SOMETHING_WENTWRONG,
        type: "fail",
        isFailed: false,
      });
    }
  };
  const viewSearchResults = async (formData) => {
    handleLibrarySearchFormJsonData({
      appliedFilteredValues: {},
      searchQuery: executeData?.searchQuery,
      powerKLibrarySearchGridDataLastRow:
        executeData?.filterDetails?.length > 0
          ? executeData?.filterDetails[executeData?.filterDetails.length - 1]
          : {},
    });
    if (
      internalAppDetails?.contentTypeId ==
      Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
    ) {
      if (buttonContent === nonDefaultAction?.displayName) {
        setOpenItems(['2']);
        if (privilagesForPowerKApp?.exportVisualization?.exportVisualizationEnabled) {
          setIsAccordionDisabled(false);
        }
        if (privilagesForPowerKApp?.export?.exportEnabled) {
          setIsExportDisabled(false);
        }
        if (!isAccordionDisabled) {
          setButtonContent(defaultAction?.displayName);
        }
      }
      setLoading(true);
      let templatID = librarySearchForm?.templateConfig?.vizualisationReportId;
      const decodeUniqeId = atob(executeData?.searchQuery);
      let obj = {
        templateid: templatID,
        uniqueid: decodeUniqeId,
      };
      let reportDetail = [];
      reportDetail.push(obj);
      let postParams = {
        contentType: internalAppDetails?.contentTypeId,
        reportDetail: reportDetail[0],
      };
      getPowerBIReportDetails(postParams, userInfo);
    } else {
      navigate("/apps/LibraryGrid", {
        state: {
          appDetails: internalAppDetails,
          privilagesPowerKApp: privilagesForPowerKApp,
          templateDetails: templateDetails,
        },
      });
    }
  };
  const getPowerBIReportDetails = async (postParams, userInfo) => {
    // if(loadPowerBiReport){
    try {
      const tokenDetails = await postData(
        postParams,
        APIEndPoints.PBREPORT_TOKEN_ENDPOINT(userInfo?.teams?.companyId),
        false,
        true
      );
      console.log("Token Details", tokenDetails);
      setPBReportConfig(tokenDetails.data);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.Errors[0]?.toLowerCase() === "access denied.") {
        setAccessDenied(true);
      } else {
        const failMessage = `Failed to load Powerbi Report: ${err?.response?.data?.Errors[0]}`;
        console.log(failMessage);
        showNotification(failMessage, "Fail");
        //setErrorApiCall(true);
      }
    }
  };
  const handleAccordionClick = (item) => {
    if (privilagesForPowerKApp?.exportVisualization?.exportVisualizationDisplay == true) {
      setOpenItems(openItems?.includes(item) ? [] : [item]);
    }
    if (item === "1" && openItems?.includes(item) === false) {
      setUpdateEditorValue({ updateEditor: true, inputSuccess: false });
    }
  };

  const _renderItemColumn = (item, index, column) => {
    const fieldContent = findValueByKey(item, column.fieldName);
    return renderColumns(fieldContent, column, item, index);
  };
  const handleCheckboxChangeForOperators = (
    e,
    value,
    fN,
    isCF,
    name,
    hE,
    eM,
    index,
    option
  ) => {
    let gridDataArr = [...gridData];
    let selRow = gridDataArr?.filter((item) => item.stepId == index + 1);
    if (selRow?.length > 0) {
      if (
        selRow[0]?.userInputs?.selectedOperators?.length > 0 &&
        selRow[0]?.userInputs?.selectedOperators[0]?.toLowerCase() === "not" &&
        selRow[0]?.uiConfigs?.reOrderIndex == 1 &&
        value?.checked == false
      ) {
        if (selRow[0]?.stepId == 1)
          gridDataArr[index].userInputs.selectedOperators = [];
        else gridDataArr[index].userInputs.selectedOperators = ["and"];
      } else {
        gridDataArr[index].userInputs.selectedOperators = [fN];
      }
      setGridData(gridDataArr);
    }
    setButtonContent(defaultAction?.displayName);
  };
  const renderColumns = (fieldContent, column, item, index) => {
    let parent = false;
    if (internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES) {
      parent = masterGroupsSorted?.length > 0 && masterGroupsSorted.filter((item) => item?.groupType == Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_PARENT_GROUP_TYPE)
        ? masterGroupsSorted.filter((fileItem) => {
          return (
            item?.searchParamsData?.groupId == fileItem?.groupId &&
            fileItem?.groupType == Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_PARENT_GROUP_TYPE
          );
        })?.[0]
          ? true
          : false
        : true;
    }
    switch (column.type) {
      case "checkbox":
        return (
          <CheckboxComponent
            label={""}
            checked={
              parent
                ? false
                : item?.userInputs?.selectedOperators?.[0]?.toLowerCase() ===
                  column?.fieldName?.toLowerCase()
                  ? true
                  : false
            }
            onChange={handleCheckboxChangeForOperators}
            index={item?.stepId - 1}
            currentItem={item}
            dontAllowToUncheck={true}
            fieldsName={column?.fieldName?.toLowerCase()}
            disabled={
              parent ||
                item?.uiConfigs?.isSelected === false ||
                (item?.uiConfigs?.reOrderIndex === 1 &&
                  column?.fieldName?.toLowerCase() !== "not")
                ? true
                : false
            }
            className={"searchFormCheckbox"}
          />
        );
      case "Date":
        return <span>{utils.convertDate(fieldContent)}</span>;
      case "LetterCase":
        return <span>{fieldContent.toLowerCase()}</span>;
      case "hyperLink":
        return (
          <>
            {parent ? (
              <b className="searchParamTypeForFootnote">
                {item?.searchParamsData?.groupName}
              </b>
            ) : (
              <p
                onClick={(event) => {
                  event.preventDefault();
                  if (item?.uiConfigs?.isSelected) openPanel(item, true);
                }}
                className="searchParamType"
              >
                {item?.searchParamsData?.groupName}
              </p>
            )}
          </>
        );
      case "description":
        return (
          <>
            <p className="searchCriteriaPTag">
              <span className="descriptionParam">{`${item?.searchParamsData?.displayName}: `}</span>
              <span className="searchCriteriaContent">
                {item?.searchParamsData?.type ===
                  Constant.LANDING_PAGE_CONSTANTS.DATEPICKER_TYPE
                  ? `${fieldContent[0]} to ${fieldContent[1]}`
                  : item?.searchParamsData?.isMultiSelect
                    ? fieldContent.join(" | ")
                    : fieldContent}
              </span>
            </p>
          </>
        );
      case "deleteIcon":
        return (
          <div className="paramActionIcons">
            <div>
              {parent ? (
                <RiDeleteBin6Line
                  size={15}
                  title="Delete"
                  className="paramActionDeleteNone"
                  disabled
                />
              ) : (
                <RiDeleteBin6Line
                  size={15}
                  title="Delete"
                  className="paramActionDelete"
                  onClick={(e) => {
                    setCurrentItemToBeDeleted(item);
                  }}
                />
              )}
            </div>
          </div>
        );
      case "selectionIcon":
        const isChecked = item?.uiConfigs?.isSelected;
        return (
          <div className="columnContainer">
            {
              checkIfDeviceIsMob() ?
                <Draggable
                  key={item?.stepId}
                  draggableId={item?.stepId ? item?.stepId?.toString()?.toLowerCase() : ""}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="dragIcon" tabIndex={0}>
                        <RxDragHandleDots2 />
                      </div>
                    </div>
                  )}
                </Draggable>
                :
                <div className="dragIcon" tabIndex={0}>
                  <RxDragHandleDots2 />
                </div>
            }
            <div className="columnCheckbox">
              <CheckboxComponent
                label={""}
                checked={isChecked}
                onChange={handleCheckboxChange}
                index={item?.stepId - 1}
                disabled={parent ? true : false}
                className={"searchFormCheckbox"}
              />
            </div>
          </div>
        );
      default:
        return (
          <span className={column.className ? column.className : ""}>
            <DescriptionCell longValues={fieldContent} />
          </span>
        );
    }
  };

  const textBoxChangeAddCharacter = (userTypedString) => {
    let afterTextArray = booleanExpression
      .substring(selectionPoints.current.startingPoint)
      .replace(/ /g, "")
      .match(/[()0-9a-zA-Z]+/g);
    let afterText =
      afterTextArray?.length > 1
        ? afterTextArray.join("")
        : afterTextArray?.length > 0
          ? afterTextArray[0]
          : afterTextArray ?? "";
    let beforeTextArray = booleanExpression
      .substring(0, selectionPoints.current.startingPoint)
      .replace(/ /g, "")
      .match(/[()0-9a-zA-Z]+/g);
    let beforeText =
      beforeTextArray?.length > 1
        ? beforeTextArray.join("")
        : beforeTextArray?.length > 0
          ? beforeTextArray[0]
          : beforeTextArray ?? "";
    switch (userTypedString) {
      case "(":
        if (afterText?.toLowerCase().startsWith("not")) {
          return true;
        } else if (
          /^\d/.test(afterText) &&
          !/\d$/.test(beforeText) &&
          !beforeText?.toLowerCase().endsWith("not")
        ) {
          return true;
        } else if (
          beforeText?.toLowerCase().endsWith("(") ||
          afterText?.toLowerCase().startsWith("(")
        ) {
          return true;
        } else {
          setValidationMessage(
            Constant.LANDING_PAGE_CONSTANTS.OPEN_PARENTHESIS_POSITION
          );
          return false;
        }
        break;
      case ")":
        if (/\d$/.test(beforeText) && !/^\d/.test(afterText)) {
          return true;
        } else if (
          beforeText?.toLowerCase().endsWith(")") ||
          afterText?.toLowerCase().startsWith(")")
        ) {
          return true;
        } else {
          setValidationMessage(
            Constant.LANDING_PAGE_CONSTANTS.CLOSE_PARENTHESIS_POSITION
          );
          return false;
        }
        break;
      case " ":
        if (/\d$/.test(beforeText) || /^\d/.test(afterText)) {
          if (/\d$/.test(beforeText) && !/^\d/.test(afterText)) {
            return true;
          } else if (!/\d$/.test(beforeText) && /^\d/.test(afterText)) {
            return true;
          } else {
            setValidationMessage(
              Constant.LANDING_PAGE_CONSTANTS.BLANK_SPACE_POSITION
            );
            return false;
          }
        } else if (
          beforeText?.toLowerCase().endsWith(")") ||
          afterText?.toLowerCase().startsWith(")") ||
          beforeText?.toLowerCase().endsWith("(") ||
          afterText?.toLowerCase().startsWith("(")
        ) {
          return true;
        } else {
          setValidationMessage(
            Constant.LANDING_PAGE_CONSTANTS.BLANK_SPACE_POSITION
          );
          return false;
        }
      default:
        setValidationMessage("");
        return false;
    }
  };
  const textBoxChangeRemoveCharacter = (userTypedString) => {
    const startEndPoints = selectionPoints.current;
    const stPoint = Math.min(
      startEndPoints?.startingPoint,
      startEndPoints?.endingPoint
    );
    const endPoint = Math.max(
      startEndPoints?.startingPoint,
      startEndPoints?.endingPoint
    );
    let diffStartAndEndPoint = endPoint - stPoint;
    if (diffStartAndEndPoint > 0) {
      const subStringToBeRemoved = booleanExpression.substring(
        stPoint,
        endPoint
      );
      if (
        /[A-Za-z]/.test(subStringToBeRemoved) ||
        /\d/.test(subStringToBeRemoved)
      ) {
        setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION);
        return false;
      } else {
        return true;
      }
    } else {
      const forBackSpace = booleanExpression.substring(0, stPoint);
      const forDelete = booleanExpression.substring(stPoint);
      switch (userTypedString) {
        case "backspace":
          if (/\d$/.test(forBackSpace) || /[A-Za-z]$/.test(forBackSpace)) {
            setValidationMessage(
              Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION
            );
            return false;
          } else {
            return true;
          }
          break;
        case "delete":
          if (/^[A-Za-z]/.test(forDelete) || /^\d/.test(forDelete)) {
            setValidationMessage(
              Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION
            );
            return false;
          } else {
            return true;
          }
          break;

        default:
          setValidationMessage("");
          return false;
      }
    }
  };
  const textBoxChange = (
    userTypedString,
    selectionPointsEditor,
    expression
  ) => {
    selectionPoints.current = selectionPointsEditor;
    let inputSuccess = false;
    if (
      userTypedString === "(" ||
      userTypedString === ")" ||
      userTypedString === " "
    ) {
      inputSuccess = textBoxChangeAddCharacter(userTypedString);
    } else if (
      userTypedString?.toLowerCase() === "delete" ||
      userTypedString?.toLowerCase() === "backspace"
    ) {
      inputSuccess = textBoxChangeRemoveCharacter(
        userTypedString?.toLowerCase()
      );
    } else {
      setValidationMessage(Constant.LANDING_PAGE_CONSTANTS.USE_ABOVE_SECTION);
    }
    if (inputSuccess) {
      setValidationMessage("");
      setBooleanExpression(expression);
      setUpdateEditorValue({ updateEditor: true, inputSuccess: true });
    } else {
      setBooleanExpression(booleanExpression);
      setUpdateEditorValue({ updateEditor: true, inputSuccess: false });
    }
  };

  const onChangeSection = (selectedGroupName) => {
    const selectedParam = masterGroupsSorted.find(
      (param) => param?.groupName === selectedGroupName
    );
    if (selectedParam) {
      openPanel(selectedParam);
    }
  };
  const insertBeforeItem = (item) => {
    if (
      internalAppDetails?.contentTypeId ===
      Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
    ) {
      const draggedItems = selection.isIndexSelected(draggedIndex)
        ? selection.getSelection()
        : [
          draggedItem !== null && draggedItem !== undefined
            ? draggedItem
            : -1,
        ];
      const insertIndex = gridData.indexOf(item);
      if (
        draggedItems?.some(
          (draggedItem) => gridData?.indexOf(draggedItem) === 0
        )
      ) {
        return;
      }
      if (
        insertIndex === 0 &&
        draggedItems.some((draggedItem) => gridData.indexOf(draggedItem) > 0)
      ) {
        return;
      }
    }
    const draggedItems = selection.isIndexSelected(draggedIndex)
      ? selection.getSelection()
      : [draggedItem !== null && draggedItem !== undefined ? draggedItem : -1];
    const insertIndex = gridData.indexOf(item);
    const filterGrid = gridData.filter(
      (data) => draggedItems.indexOf(data) === -1
    );
    filterGrid.splice(insertIndex, 0, ...draggedItems);
    const firstSelectedIndex =
      filterGrid.filter((item) => item?.uiConfigs?.isSelected)?.[0] ?? {};
    const modifiedIndexOfMovedStep = filterGrid.findIndex(
      (item) => item?.stepId === firstSelectedIndex?.stepId
    );
    filterGrid[modifiedIndexOfMovedStep].uiConfigs.reOrderIndex = -1;
    const updatedGrid = filterGrid.map((data, index) => {
      const newData = { ...data, stepId: index + 1 };
      if (index === 0) {
        if (
          newData.userInputs.selectedOperators.length > 0 &&
          newData.userInputs.selectedOperators[0]?.toLowerCase() === "not"
        ) {
          newData.userInputs.selectedOperators =
            firstSelectedIndex?.userInputs?.selectedOperators;
        } else {
          newData.userInputs.selectedOperators = [];
        }
        if (newData.uiConfigs.reOrderIndex === -1) {
          newData.uiConfigs.reOrderIndex = 1;
        } else {
          newData.uiConfigs.reOrderIndex = 0;
        }
      } else {
        if (newData.uiConfigs.reOrderIndex === -1) {
          newData.uiConfigs.reOrderIndex = 1;
        } else {
          newData.uiConfigs.reOrderIndex = index + 1;
        }
      }
      if (index > 0 && newData?.userInputs?.selectedOperators?.length < 1) {
        newData.userInputs.selectedOperators = ["AND"];
      }
      return newData;
    });
    const initialIndex = updatedGrid.indexOf(draggedItems[0]);
    if (initialIndex !== insertIndex) {
      setButtonContent(defaultAction?.displayName);
    }
    setGridData(updatedGrid);
  };

  const onDragStart = (
    gridData,
    itemIndex,
    selectedItems,
    event,
    columnIndex
  ) => {
    setDraggedItem(gridData);
    setDraggedIndex(
      itemIndex !== null && itemIndex !== undefined ? itemIndex : -1
    );
  };
  const onDragEnd = (result) => {
    if (checkIfDeviceIsMob()) {
      const { destination, source } = result;
      if (!destination) {
        return;
      }
      const draggedItems = selection.isIndexSelected(source.index)
        ? selection.getSelection()
        : [gridData[source.index]];
      const insertIndex = destination.index;
      if (
        internalAppDetails?.contentTypeId ===
        Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
      ) {
        if (
          draggedItems?.some(
            (draggedItem) => gridData?.indexOf(draggedItem) === 0
          )
        ) {
          return;
        }
        if (
          insertIndex === 0 &&
          draggedItems.some((draggedItem) => gridData.indexOf(draggedItem) > 0)
        ) {
          return;
        }
      }
      const filterGrid = gridData.filter(
        (data) => !draggedItems.includes(data)
      );
      filterGrid.splice(insertIndex, 0, ...draggedItems);
      const firstSelectedIndex =
        filterGrid.find((item) => item?.uiConfigs?.isSelected) ?? {};
      const modifiedIndexOfMovedStep = filterGrid.findIndex(
        (item) => item?.stepId === firstSelectedIndex?.stepId
      );
      if (modifiedIndexOfMovedStep !== -1) {
        filterGrid[modifiedIndexOfMovedStep].uiConfigs.reOrderIndex = -1;
      }
      const updatedGrid = filterGrid.map((data, index) => {
        const newData = { ...data, stepId: index + 1 };
        if (index === 0) {
          if (
            newData.userInputs.selectedOperators.length > 0 &&
            newData.userInputs.selectedOperators[0]?.toLowerCase() === "not"
          ) {
            newData.userInputs.selectedOperators =
              firstSelectedIndex?.userInputs?.selectedOperators;
          } else {
            newData.userInputs.selectedOperators = [];
          }
          newData.uiConfigs.reOrderIndex = newData.uiConfigs.reOrderIndex === -1 ? 1 : 0;
        } else {
          newData.uiConfigs.reOrderIndex = newData.uiConfigs.reOrderIndex === -1 ? 1 : index + 1;
        }
        if (index > 0 && newData?.userInputs?.selectedOperators?.length < 1) {
          newData.userInputs.selectedOperators = ["AND"];
        }
        return newData;
      });
      setGridData(updatedGrid);
    }
    else {
      setDraggedItem(undefined);
      setDraggedIndex(-1);
    }
  };

  const onDrop = (gridData, event) => {
    setDraggedItem(gridData);
    if (draggedItem) {
      insertBeforeItem(gridData);
    }
  };
  const dragDropEventsCalls = {
    canDrag: () => true,
    canDrop: () => true,
    onDragStart,
    onDragEnd,
    onDrop,
  };

const disableDropdownItems = (
    internalAppDetails?.contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES &&
    gridData?.length === 0 &&
    updatedMasterGroups?.length > 0 &&
    updatedMasterGroups?.map(
      (param) =>
        param?.disabled === false &&
        param?.groupType === Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_PARENT_GROUP_TYPE
    )
  );

  const commonGridFooter = () => {
    return (
      <>
        {
          (((librarySearchForm?.searchPageConfig?.type == Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_ACCORDION) && (librarySearchForm?.searchPageConfig?.accordionConfig?.accordions?.[0]?.sections?.length > 0 && librarySearchForm?.searchPageConfig?.accordionConfig?.accordions?.[0]?.sections?.includes("steps"))) ||
            ((librarySearchForm?.searchPageConfig?.type == Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_PLAIN) && (librarySearchForm?.searchPageConfig?.sections?.steps?.columns?.length > 0 && librarySearchForm?.searchPageConfig?.sections?.hasOwnProperty('steps'))))
          &&
          (
            checkIfDeviceIsMob() ?
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <DetailsList
                        items={gridData}
                        columns={columnsForSearchGrid}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionPreservedOnEmptyClick={true}
                        selectionMode={SelectionMode.multiple}
                        isSelectedOnFocus={false}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        onRenderItemColumn={_renderItemColumn}
                        // onRenderRow={(props) => {
                        //   if (props) {
                        //     return (
                        //       <Draggable
                        //         key={props?.item?.stepId}
                        //         draggableId={props?.item?.stepId ? props?.item?.stepId?.toString()?.toLowerCase() : ""}
                        //         index={props.itemIndex}
                        //       >
                        //         {(provided) => (
                        //           <div
                        //             ref={provided.innerRef}
                        //             {...provided.draggableProps}
                        //             {...provided.dragHandleProps} 
                        //           >
                        //              <DetailsRow {...props} />
                        //           </div>
                        //         )}
                        //       </Draggable>
                        //     );
                        //   }
                        //   return null;
                        // }}
                        selectionIndex={0}
                        checkboxVisibility={2}
                        onActiveItemChanged={() => { }}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(headerProps, defaultRender) => {
                          if (window.innerWidth >= 900) {
                            return defaultRender({
                              ...headerProps,
                              onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                                return <TooltipHost {...tooltipHostProps} />;
                              },
                            });
                          } else {
                            return defaultRender({
                              ...headerProps,
                              onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                                return <TooltipHost {...tooltipHostProps} />;
                              },
                              styles: {
                                root: {
                                  selectors: {
                                    ".ms-DetailsHeader-cell": {
                                      whiteSpace: "normal",
                                      textOverflow: "clip",
                                      lineHeight: "normal",
                                    },
                                    ".ms-DetailsHeader-cellTitle": {
                                      height: "100%",
                                      alignItems: "center",
                                    },
                                  },
                                },
                              },
                            });
                          }
                        }}
                        className={"searchPageDetailsList"}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              :
              <DetailsList
                items={gridData}
                columns={columnsForSearchGrid}
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.multiple}
                isSelectedOnFocus={false}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                onRenderItemColumn={_renderItemColumn}
                draggable={true}
                dragDropEvents={dragDropEventsCalls}
                selectionIndex={0}
                checkboxVisibility={2}
                onActiveItemChanged={() => { }}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  if (window.innerWidth >= 900) {
                    return defaultRender({
                      ...headerProps,
                      onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                        return <TooltipHost {...tooltipHostProps} />;
                      },
                    });
                  } else {
                    return defaultRender({
                      ...headerProps,
                      onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                        return <TooltipHost {...tooltipHostProps} />;
                      },
                      styles: {
                        root: {
                          selectors: {
                            ".ms-DetailsHeader-cell": {
                              whiteSpace: "normal",
                              textOverflow: "clip",
                              lineHeight: "normal",
                            },
                            ".ms-DetailsHeader-cellTitle": {
                              height: "100%",
                              alignItems: "center",
                            },
                          },
                        },
                      },
                    });
                  }
                }}
                className={"searchPageDetailsList"}
              />
          )
        }
        {!gridData?.length > 0 && (
          <div className="letGetStartContainer">
            <div className="letGetStartLabel">
              Let's get started!
            </div>
            <div className="letGetStartIcon">
              <img
                src={
                  require("../../../assets/SlideSearch.svg").default
                }
                alt="Search"
                className="letGetStartImage"
              />
            </div>
            <div className="letGetStartSubText">
              Choose a search parameter to begin.
            </div>
          </div>
        )}
        {
          (((librarySearchForm?.searchPageConfig?.type == Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_ACCORDION) && (librarySearchForm?.searchPageConfig?.accordionConfig?.accordions?.[0]?.sections?.length > 0 && librarySearchForm?.searchPageConfig?.accordionConfig?.accordions?.[0]?.sections?.includes("expressionsAndResults"))) ||
            ((librarySearchForm?.searchPageConfig?.type == Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_PLAIN) && (librarySearchForm?.searchPageConfig?.sections?.expressionsAndResults?.columns?.length > 0 && librarySearchForm?.searchPageConfig?.sections?.hasOwnProperty('expressionsAndResults')))) &&
          <>
            {gridData?.length > 0 && (
              <div className="searchPageFooter">
                <div className="booleanParametersContainer">
                  <div className="booleanParametersFieldsLeft">
                    <div className="booleanParametersFieldwithIcon">
                      <Label
                        content={librarySearchForm?.searchPageConfig?.sections?.expressionsAndResults?.columns?.[0]?.displayName}
                        title={librarySearchForm?.searchPageConfig?.sections?.expressionsAndResults?.columns?.[0]?.displayName}
                        className="booleanLabel"
                      />
                      <TooltipHost
                        content={
                          <>
                            {Constant.LANDING_PAGE_CONSTANTS.THE} <b>{Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAMETER}</b> {Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAMETERS_SEARCH}
                          </>
                        }
                      >
                        <IoInformationCircle />
                      </TooltipHost>
                    </div>
                    <BooleanExpressionEditor
                      expression={booleanExpression}
                      textBoxChange={textBoxChange}
                      className="booleanExpressionTextBox"
                      setUpdateEditorValue={setUpdateEditorValue}
                      updateEditorValue={updateEditorValue}
                    />
                    <p className={validationMessage?.length < 1 ? "booleanParameterNote" : "booleanParameterErrorNote"}>
                      {validationMessage?.length > 1
                        ? validationMessage
                        : `${Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAM_CLICK_EXECUTE_MESSAGE}
                              ${Constant.LANDING_PAGE_CONSTANTS.BOOLEAN_PARAM_CLICK_SEARCH_RESULTS_MESSAGE}`}
                    </p>
                  </div>
                  <div className="booleanParametersFieldsRight">
                    <div className="booleanParametersButtonFields">
                      {librarySearchForm?.searchPageConfig?.sections?.expressionsAndResults?.columns?.slice(1).map((column, index) => (
                        <div key={index} className={index === 0 ? "stepsAppliedFeilds" : "totalSearchResults"}>
                          <Label className="booleanLabel" content={column?.displayName} />
                          <TextBox
                            onChange={controlsChangeHandler}
                            name={column?.displayName}
                            disabled={true}
                            value={
                              index === 0
                                ? gridData?.filter((item) => item?.uiConfigs?.isSelected === true)?.length.toString() ?? "0"
                                : searchFormPayload?.customData?.totalSearchResults === 0
                                  ? "0"
                                  : searchFormPayload?.customData?.totalSearchResults
                            }
                            id={column?.displayName}
                            isLibrarySearch={true}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="searchPageFooterButtons">
                      <Button
                        className={`excuteButton ${buttonContent === Constant.LANDING_PAGE_CONSTANTS.VIEW_REPORTS ? "viewReport" : ""}`}
                        size="small"
                        onClick={handleExecuteClick}
                        content={buttonContent}
                        disabled={disableExecuteButton}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        }
      </>
    )
  };
  if (errorApiCall) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;

  return (
    <>
      {isLoading ? (
        <ReusableLoader isOverlay={true} isAppBody={true} />
      ) : (
        <>
          {isGridLoading && (
            <ReusableLoader isOverlay={true} isAppBody={true} />
          )}
          <div
            className={`searchPageContainer ${gridData?.length > 0 ? "searchPageContainerHeight" : ""
              }`}
          >
            <div className="searchFiltersContainer">
              <div className="searchFiltersLabel">
                {Constant.LANDING_PAGE_CONSTANTS.SEARCH_PARAMETERS}:
              </div>
              <div className="searchFilterAllPills">
                {masterGroupsSorted?.length > 0 &&
                  masterGroupsSorted?.map((param) => (
                    <Button
                      className="searchFilterPills"
                      key={param?.groupId}
                      onClick={() => {
                        openPanel(param);
                      }}
                      size="small"
                      content={param?.groupName}
                      disabled={isButtonDisabled(param)}
                    />
                  ))}
              </div>
            </div>
            {/* search parameter dropdown for mobile screens */}
            {checkIfDeviceIsMob() && (
              <div className={`searchParametersMobile ${internalAppDetails?.contentTypeId === Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
                ? (disableDropdownItems ? 'parent' : 'child')
                : ''
                }`}>
                <DropdownComponent
                  items={
                    internalAppDetails?.contentTypeId ===
                      Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
                      ? updatedMasterGroups?.length > 0 &&
                      updatedMasterGroups?.map((param) => param?.groupName)
                      : masterGroupsSorted?.length > 0 &&
                      masterGroupsSorted?.map((param) => param?.groupName)
                  }
                  onDropDownChange={(e) => {
                    setSelectedSection(e);
                    onChangeSection(e);
                  }}
                  multiple={false}
                  placeholder={"Select parameters"}
                  initialLoad={false}
                  name={"Section"}
                  value={selectedSection}
                  renderColumns={renderColumns}
                />
              </div>
            )}

            {librarySearchForm?.searchPageConfig?.type ==
              Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_ACCORDION &&
              librarySearchForm?.searchPageConfig?.accordionConfig?.accordions
                ?.length > 0 && (
                <div className="footnotesSearchPageGrid">
                  <Accordion collapsible openItems={openItems}>
                    <AccordionItem value="1">
                      <AccordionHeader
                        className="footnotesAccordionHeaders"
                        onClick={() => handleAccordionClick("1")}
                      >
                        {
                          librarySearchForm?.searchPageConfig?.accordionConfig
                            ?.accordions?.[0]?.displayName
                        }
                      </AccordionHeader>
                      {openItems.includes("1") && (
                        <AccordionPanel className="footnotesAccordionBody">
                          <div
                            className={`clearsSelection ${!gridData.length > 0 ? "disabled" : ""
                              }`}
                          >
                            <span onClick={handleClearSelection}>
                              <TbXboxX />
                              <span className="clearsSelectionText">
                                {Constant.INTERNALAPP_CONSTANTS.CLEAR_SELECTION}
                              </span>
                            </span>
                          </div>
                          {commonGridFooter()}
                        </AccordionPanel>
                      )}
                    </AccordionItem>
                    <AccordionItem
                      value="2"
                      className="footnotesAccordionHeaders"
                      disabled={isAccordionDisabled}
                      onClick={() => handleAccordionClick("2")}
                    >
                      <AccordionHeader>
                        {
                          librarySearchForm?.searchPageConfig?.accordionConfig
                            ?.accordions?.[1]?.displayName
                        }
                      </AccordionHeader>
                      {openItems.includes("2") && (
                        <AccordionPanel className="footnotesAccordionBody">
                          <div className="reportDetailsViewPage">
                            <PowerBiReport
                              item={pbReportConfig}
                              closeLoader={closeLoader}
                            />
                          </div>
                        </AccordionPanel>
                      )}
                    </AccordionItem>
                  </Accordion>
                  {privilagesForPowerKApp?.export?.exportDisplay && (
                    <div className={`footnotesFooter ${!openItems?.length > 0 ? 'exportBtnBottom' : ''}`}>
                      <div className="exportToExcelBtn">
                        <Button
                          className="excuteButton"
                          size="small"
                          id="exportId"
                          onClick={exportFootnoteReports}
                          content={
                            librarySearchForm?.searchPageConfig?.accordionConfig
                              ?.accordions?.[1]?.buttons[0]?.name
                          }
                          disabled={isExportDisabled}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

            {librarySearchForm?.searchPageConfig?.type ==
              Constant.INTERNALAPP_CONSTANTS.FOOTNOTE_LIBRARY_PLAIN && (
                <div ref={scrollDiv}>
                  <div
                    className={`${!gridData?.length > 0
                        ? "detailslist_wrapper initialGridHeight"
                        : "detailslist_wrapper "
                      }`}
                  >
                    {commonGridFooter()}
                  </div>
                </div>
              )}
            <PanelView
              closePanel={() => {
                closePanel();
              }}
              panelOpen={panelOpen}
              param={currentParam}
              renderControl={(item) => renderControl(item)}
              addGridData={(isEdit) => addGridData(isEdit)}
              updateStepData={updateStepData}
              searchFormStepData={searchFormStepData}
              panelLoader ={panelLoader}
              textBoxControlDisabled={errorState.Description.hasAnyError}
            />
            <DialogBox
              cancelButton={Constant.INTERNALAPP_CONSTANTS.BTN_CANCEL}
              confirmButton={Constant.INTERNALAPP_CONSTANTS.BTN_DELETE}
              onCancelMethod={() => {
                setCurrentItemToBeDeleted({});
              }}
              onConfirmMethod={() => {
                deleteStep(currentItemToBeDeleted);
              }}
              openDialog={Object.keys(currentItemToBeDeleted)?.length > 0}
              content={
                <div className="deleteStepMessage">
                  <p>{Constant.INTERNALAPP_CONSTANTS.DELETE_SEARCH_STEP_MSG}</p>
                </div>
              }
              headerName={`${Constant.INTERNALAPP_CONSTANTS.DELETE_SEARCH_STEP_HEADER} ${currentItemToBeDeleted?.stepId}`}
              className={`dialogBoxSearchStepDelete`}
            />
          </div>
        </>
      )}
    </>
  );
}

const DescriptionCell = ({ longValues }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const textToDisplay = useMemo(() => {
    return isExpanded ? longValues : longValues?.slice(0, 100);
  }, [isExpanded, longValues]);
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <span className={isExpanded ? "expand" : "truncate"}>
        {textToDisplay}
      </span>
    </div>
  );
};
export default LandingPage;
