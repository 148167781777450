import React, { useEffect, useState } from "react";
import {
  MdVisibility,
  MdOutlineHowToVote,
  MdThumbUp,
  MdChatBubble,
  MdSend,
  MdDownload,
  MdMailOutline,
} from "react-icons/md";
import Chat from "../../components/chat/chat";
import SharePopup from "../../components/sharepopup/sharePopup";
import CopyLinkPopUp from "../../components/sharepopup/sharelink/copylink/copyLinkPopUp";
import SendLinkPopUp from "../../components/sharepopup/sharelink/sendlink/sendLinkPopUp";
import CalenderPopup from "../../components/calenderpopup/calenderPopup";
import { FormContext } from "../../context/genericdatacontext/formdatacontext";
import { BiLike } from "react-icons/bi";
import APIEndPoints from "../../utility/apiendpoints";
import { postData } from "../../services/customApis";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { app } from "@microsoft/teams-js";
import Constant from "../../utility/constants";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import MenuButton from "../menubutton/menuButton";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import { TrackEvent } from "../../services/appInsights";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import {
  getContentTypeNamesById2,
  updateMetaDataCounts,
} from "../../utility/utility";
import { checkIfDeviceIsMob } from "../../utility/utility";
import { sharing } from "@microsoft/teams-js";
import { buildDeepLink } from "../../utility/utility";
import { UseDeeplinkContext } from "../../context/deeplincontext/deeplinkContext";
import touchIcon from "../../assets/touch.svg";
import { GrClose } from "react-icons/gr";
import { AiFillCheckCircle } from "react-icons/ai";
import _ from "lodash";
import CustomizedToolTip from "../tooltip/toolTip";
import { Web_Version_Message } from "../../commonui/staticDialogMessage/staticDialogMessage";
import { Image } from "@fluentui/react-northstar";
import { HiDownload } from "react-icons/hi";
import "./ActionBar.css";

const ActionBar = (props) => {
  let cardItem = props.item;
  let applicationLevel = props.item?.customData
    ? props.item?.customData?.applicationLevel
    : {};
  let userLevel = props.item?.customData
    ? props.item?.customData?.userLevel
    : {};
  let config = props?.config;
  let contentType = props.contentType;
  let { deeplinkUrl } = UseDeeplinkContext();

  const [views, setViews] = useState(
    props.item?.customData?.applicationLevel.viewCount
  );
  const [menuItems, setMenuItems] = useState([]);
  const [loggedInM365Disable, setloggedInM365Disable] = useState(false);
  const checkIfAttachmentExists = () => {
    if (cardItem?.contentInfo[0]?.contentSourceType?.toLowerCase() == "html") {
      return (
        cardItem?.attachments != null && cardItem?.attachments != undefined
      );
    } else {
      if (props?.showMoreButton) {
        return (
          cardItem?.contentInfo[0]?.content?.fileDetail?.fileIdentifier !=
            undefined &&
          cardItem?.contentInfo[0]?.content?.fileDetail?.fileIdentifier != null
        );
      } else {
        return (
          cardItem?.contentInfo[0]?.contentFileInfo?.fileDetail
            ?.fileIdentifier != undefined &&
          cardItem?.contentInfo[0]?.contentFileInfo?.fileDetail
            ?.fileIdentifier != null
        );
      }
    }
  };
  const isAttachment = checkIfAttachmentExists();

  const contentCustomData = cardItem?.contentCustomData;

  const showMore = () => {
    if (
      Array.isArray(contentCustomData?.tileAction) &&
      contentCustomData?.tileAction?.length > 0
    ) {
      if (
        (config?.register?.registerDisplay &&
          contentCustomData?.tileAction?.filter((item) => {
            return item?.customData?.displayName?.toLowerCase() == "register";
          })?.[0]?.customData?.registrationUrl !== "") ||
        (config?.ctaRequestAccess?.ctaRequestAccessDisplay &&
          contentCustomData?.tileAction?.filter((item) => {
            return (
              item?.customData?.displayName?.toLowerCase() == "request access"
            );
          })) ||
        (config?.ctaRequestDemo?.ctaRequestDemoDisplay &&
          contentCustomData?.tileAction?.filter((item) => {
            return (
              item?.customData?.displayName?.toLowerCase() == "request demo"
            );
          })) ||
        (config?.ctaTalkToEY?.ctaTalkToEYDisplay &&
          contentCustomData?.tileAction?.filter((item) => {
            return (
              item?.customData?.displayName?.toLowerCase() ==
              "talk to your ey team"
            );
          }))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const [like, setLike] = useState(applicationLevel.likeCount);
  const [waitingFlag, setwaitingFlag] = useState(false);
  const [isLike, setIsLike] = useState(userLevel.liked);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [webVersionDisable, setWebVersionDisable] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(
    props.showMoreButton &&
      (showMore() ||
        (config?.download?.downloadDisplay &&
          isAttachment &&
          !checkIfDeviceIsMob()))
  );
  const [regURL, setRegURL] = useState(
    Array.isArray(contentCustomData?.tileAction) &&
      contentCustomData?.tileAction?.filter((item) => {
        return item?.customData?.displayName?.toLowerCase() == "register";
      })?.[0]?.customData?.registrationUrl
  );
  const [CTAMenu, setCTAMenu] = useState([]);
  const [openCTADialog, setOpenCTADialog] = useState(false);
  const [CTAActionId, setCTAActionId] = useState("");
  let { userInfo } = UseUserInfoContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  const onLikeButtonClick = async () => {
    setwaitingFlag(true);
    if (props.actionForDisablingCancel) {
      props.actionForDisablingCancel(false);
    }
    updateLocalStorage();
    updateMetaDataCounts(
      cardItem.metaDataId,
      undefined,
      isLike ? -1 : 1,
      undefined,
      true
    );
    setTimeout(() => {
      setLike(like + (isLike ? -1 : 1));
      setIsLike(!isLike);
      setwaitingFlag(false);
    }, 500);

    let likeData = {
      metaDataId: cardItem.metaDataId,
      isLiked: !isLike,
    };

    await postData(
      likeData,
      APIEndPoints.CONTENT_LIKE(
        props.contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        if (response.data.statusCode == "STCS002" || response.data == "") {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.ContentNotAvailable,
            type: "fail",
            isFailed: false,
          });
        } else {
          //TESTED
          TrackEvent(
            likeData.isLiked
              ? TelemetryEventNames.UCC_CONTENT_LIKE_EVENT
              : TelemetryEventNames.UCC_CONTENT_DISLIKES_EVENT,
            userInfo,
            {
              SectionName:
                props?.contentType == 4
                  ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                  : TelemetryEventNames.SECTION_TYPE_CONTENT,
              ContentTypeId: props.contentType.toString() ?? "",
              ContentTypeName:
                getContentTypeNamesById2(props.contentType) ?? "",
              MetadataId: props.item.metaDataId.toString(),
              UniqueId: props.item.contentUniqueId ?? "",
              Title: props.item.title ?? "",
              PublishDate: props.item.publishedDate ?? "",
              LatestLikeCount:
                response.data.resultData.likeCount.toString() ?? "",
            }
          );
        }

        //props.leftAlignActionBarCss == undefined && updateLocalStorage();
        if (props.actionForDisablingCancel) {
          props.actionForDisablingCancel(true);
        }
      })

      .catch((err) => {
        console.log(err);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };
  const updateLocalStorage = () => {
    let dataFromLS = JSON.parse(
      localStorage.getItem(props?.contentTypeName) || "{}"
    );
    if (Object.keys(dataFromLS).length != 0) {
      if (dataFromLS?.allCardsData?.sections?.new == undefined) {
        const index = dataFromLS?.allCardsData?.sections?.all?.findIndex(
          (obj) => {
            return obj.metaDataId === cardItem.metaDataId;
          }
        );
        if (index !== -1) {
          dataFromLS.allCardsData.sections.all[
            index
          ].customData.applicationLevel.likeCount = like + (isLike ? -1 : 1);
          dataFromLS.allCardsData.sections.all[
            index
          ].customData.userLevel.liked = !isLike;
        }
        localStorage.setItem(
          props?.contentTypeName,
          JSON.stringify(dataFromLS)
        );
      } else {
        let curNav = props?.contentTypeName;
        let cardData = JSON.parse(localStorage.getItem(curNav));
        if (cardData) {
          const new_index = cardData.allCardsData.sections.new.findIndex(
            (obj) => {
              return obj.metaDataId === cardItem.metaDataId;
            }
          );
          if (new_index !== -1) {
            cardData.allCardsData.sections.new[
              new_index
            ].customData.applicationLevel.likeCount = like + (isLike ? -1 : 1);
            cardData.allCardsData.sections.new[
              new_index
            ].customData.userLevel.liked = !isLike;
          }
          const tp_index = cardData.allCardsData.sections.topprevious.findIndex(
            (obj) => {
              return obj.metaDataId === cardItem.metaDataId;
            }
          );
          if (tp_index !== -1) {
            cardData.allCardsData.sections.topprevious[
              tp_index
            ].customData.applicationLevel.likeCount = like + (isLike ? -1 : 1);
            cardData.allCardsData.sections.topprevious[
              tp_index
            ].customData.userLevel.liked = !isLike;
          }
          localStorage.setItem(curNav, JSON.stringify(cardData));
        }
      }
    }
    if (props?.actionForDisablingCancel) {
      props.actionForDisablingCancel(true);
    }
  };
  // console.log(config.download.downloadEnabled, isAttachment)
  // console.log(!config.download.downloadEnabled && !isAttachment)
  //Toggle chat modal
  const [isChatModalOpen, setisChatModalOpen] = useState(false);
  const hideChatModal = () => {
    setisChatModalOpen(!isChatModalOpen);
  };

  //Toggle share modal
  const [isShareModalOpen, setisShareModalOpen] = useState(false);
  const hideShareModal = () => {
    console.log("hideShareModal", isShareModalOpen);
    //userInfo.loggedInWeb === true ? setisShareModalOpen(isShareModalOpen) : setisShareModalOpen(!isShareModalOpen);
    setisShareModalOpen(!isShareModalOpen);
  };
  //Toggle copyLink modal
  const [isCopyLinkModalOpen, setisCopyLinkModalOpen] = useState(false);
  const hideCopyLinkModal = () => {
    setisShareModalOpen(false);
    setisCopyLinkModalOpen(!isCopyLinkModalOpen);
  };

  //Toggle sendLink modal
  const [isSendLinkModalOpen, setisSendLinkModalOpen] = useState(false);
  const hideSendLinkModal = () => {
    console.log(isShareModalOpen, isSendLinkModalOpen);
    setisShareModalOpen(false);
    setisSendLinkModalOpen(!isSendLinkModalOpen);
  };

  //export visualization as ppt

  const handleExportVisualization = async () => {
    await postData(
      {
        templateId:
          cardItem.contentInfo?.[0].content?.requestJson?.template?.id,
        sourceContentId: cardItem.metaDataId,
        uniqueId:
          cardItem.contentInfo?.[0].content?.requestJson?.dataInstanceId,
      },
      APIEndPoints.EXPORT_VISUALIZATION(
        props.contentType.toString(),
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        if (response.data.status === false) {
          throw Error;
        } else {
          if (
            response.data.response !== null &&
            response.data.fileStream == null
          ) {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: true,
              completed: true,
              msg: response.data.responseMessage,
              type: "Info",
              isFailed: false,
            });
          }
          if (response.data.fileStream !== null) {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: true,
              completed: true,
              msg: "Downloading content...",
              type: "Info",
              isFailed: false,
            });
            const filename = response.data.fileName,
              base64Url = response.data.fileStream;

            //convert base64 to array buffer
            const binaryString = window.atob(base64Url);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              let ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            //create blob obj and download file
            const blob = new Blob([bytes]);
            const fileName = `${filename}`;
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              const link = document.createElement("a");
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // alert("Download attachment successful");
              }
            }
          }
        }
      })
      .catch((err) => {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors,
          type: "fail",
          isFailed: false,
        });
      });
  };

  //enable/disable download button based on file available
  const handleAttachmentDownload = async () => {
    handleNotificationMsgsData({
      showMsgBar: true,
      started: true,
      completed: false,
      msg: "Downloading content...",
      type: "Info",
      isFailed: false,
    });
    await postData(
      {
        metaDataId: cardItem.metaDataId,
        contentType: props.contentType,
        // attachments: cardItem.attachments,
        attachments: frameThePayloadForDownload(),
      },

      APIEndPoints.DOWNLOAD_ATTACHMENT(
        props.contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        if (response.data.status === false) {
          // alert("Download attachment failure!");
          throw Error;
        } else {
          if (response.data.attachments !== null) {
            response.data.attachments.map((item) => {
              const filename = item.fileName,
                // extension = item.fileType,
                base64Url = item.fileStream;

              //convert base64 to array buffer
              const binaryString = window.atob(base64Url);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
              }

              //create blob obj and download file
              const blob = new Blob([bytes]);
              const fileName = `${filename}`;
              if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName);
              } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                  const url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", fileName);
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  // alert("Download attachment successful");
                }
              }
              TrackEvent(
                TelemetryEventNames.UCC_CONTENT_DOWNLOADS_EVENT,
                userInfo,
                {
                  SectionName:
                    props?.contentType == 4
                      ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                      : TelemetryEventNames.SECTION_TYPE_CONTENT,
                  ContentTypeId: props.contentType.toString() ?? "",
                  ContentTypeName:
                    getContentTypeNamesById2(props.contentType) ?? "",
                  MetadataId: props.item.metaDataId.toString(),
                  UniqueId: props.item.contentUniqueId ?? "",
                  Title: props.item.title ?? "",
                  PublishDate: props.item.publishedDate ?? "",
                  FileName: filename ?? "",
                  //FileType: extension ?? "",
                }
              );
            });
          }
        }
      })
      .then(() => {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: "Content downloaded...",
          type: "Success",
          isFailed: false,
        });
      })
      .catch((error) => {
        console.log(error);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: error?.response?.data?.Errors
            ? error?.response?.data?.Errors
            : "Download attachment failure!",
          type: "fail",
          isFailed: false,
        });
      });
  };

  const frameThePayloadForDownload = () => {
    let tempArr = [];
    let fileDetails = undefined;

    if (cardItem?.attachments) {
      return cardItem?.attachments;
    } else {
      fileDetails = props?.showMoreButton
        ? cardItem?.contentInfo[0]?.content
        : cardItem?.contentInfo[0]?.contentFileInfo;
      tempArr.push(fileDetails);
      return tempArr;
    }
  };

  const determineTheUrl = () => {
    let url = "";
    if (props.contentType !== 4) {
      if (props?.item?.articleUrl !== undefined) {
        url = props?.item?.articleUrl;
      } else if (props?.item?.contentInfo[0]?.url !== "") {
        try {
          url = atob(props?.item?.contentInfo[0]?.url);
        } catch {
          url = props?.item?.contentInfo[0]?.url;
        }
      }
    }
  };

  useEffect(() => {
    setViews(props.item?.customData?.applicationLevel.viewCount);
  }, [props?.changeViewCount, props]);

  const shareToTeams = () => {
    if (sharing.isSupported()) {
      let curURL = determineTheUrl();
      let deeplink = buildDeepLink(
        deeplinkUrl,
        props.contentType,
        userInfo.teams.companyId,
        props.item.metaDataId,
        curURL,
        props?.item?.contentInfo?.[0] != undefined
          ? props?.item?.contentInfo?.[0].contentSourceType
          : undefined,
        props.contentType == 4
          ? props.item.contentInfo[0].content?.requestJson?.dataInstanceId !=
            null
            ? props.item.contentInfo[0].content?.requestJson?.dataInstanceId
            : ""
          : "",
        props.contentType == 4
          ? props.item.contentInfo[0].content.requestJson.template != null
            ? props.item.contentInfo[0].content.requestJson.template.id
            : ""
          : "",
        props?.item?.pinDetail?.pinMetaDataId || props?.pinMetadataId,
        props?.isBulletinBoard,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : props?.item?.contentSecurity?.availability?.domainLevel ==
            Constant.LOCAL
          ? false
          : null
      );

      if (!userInfo?.teams?.isClientTenant) {
        deeplink = deeplink + "&tenantId=" + Constant.TENANT_ID;
      }

      console.log(props);
      sharing
        .shareWebContent({
          content: [
            {
              type: "URL",
              url: deeplink,
              preview: true,
              message:
                props.contentType === 7
                  ? "<p></p></br><strong>" +
                    `${window.atob(props?.item?.title)}` +
                    "<strong>"
                  : "<p></p></br><strong>" +
                    `${props?.item?.title}` +
                    "<strong>",
            },
          ],
        })
        .then((item) => {
          TrackEvent(
            TelemetryEventNames.UCC_CONTENT_INITIATE_SHARETOTEAMS_EVENT,
            userInfo,
            {
              SectionName:
                props?.contentType == 4
                  ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                  : TelemetryEventNames.SECTION_TYPE_CONTENT,
              ContentTypeId: props.contentType.toString() ?? "",
              ContentTypeName:
                getContentTypeNamesById2(props.contentType) ?? "",
              MetadataId: props.item.metaDataId.toString(),
              UniqueId: props.item.contentUniqueId ?? "",
              Title: props.item.title ?? "",
              PublishDate: props.item.publishedDate ?? "",
            }
          );
        })
        .catch((err) => {
          console.log("some error occurred", err);
        });
    }
  };

  useEffect(() => {
    updateMetaDataCounts(
      cardItem.metaDataId,
      undefined,
      isLike ? 1 : -1,
      undefined,
      true,
      applicationLevel?.likeCount
    );
    app.getContext().then((context) => {
      if (
        context?.app?.host?.name === "Outlook" ||
        context?.app?.host?.name === "Office"
      ) {
        setloggedInM365Disable(true);
      }
    });
  }, []);

  useEffect(() => {
    let menuArr = [];
    if (Array.isArray(contentCustomData?.tileAction)) {
      let eachMeuItem = {};
      let sortedArr = _.sortBy(contentCustomData?.tileAction, (item) =>
        item?.customData?.displayName?.toLowerCase()
      );
      sortedArr?.map((item) => {
        if (item?.type?.toLowerCase() == "contentregister") {
          if (config?.register?.registerDisplay && regURL !== "") {
            eachMeuItem = {
              key: item?.type?.toLowerCase(),
              name: item?.customData?.displayName,
              status: false,
              disabled: !config?.register?.registerEnabled,
              onClick: () => openRegisterUrl(regURL),
              content: (
                <div tabIndex={0} className="moreIcons">
                  <p>{item?.customData?.displayName}</p>
                </div>
              ),
            };
            menuArr.push(eachMeuItem);
          }
        }
        if (item?.type?.toLowerCase() == "contentrequestaccess") {
          if (config?.ctaRequestAccess?.ctaRequestAccessDisplay) {
            eachMeuItem = {
              key: item?.type?.toLowerCase(),
              name: item?.customData?.displayName,
              status: false,
              disabled: !config?.ctaRequestAccess?.ctaRequestAccessEnabled,
              onClick: () => {
                onClickEachCTA(item);
              },
              content: (
                <div tabIndex={0} className="moreIcons">
                  <p>{item?.customData?.displayName}</p>
                </div>
              ),
            };
            menuArr.push(eachMeuItem);
          }
        }
        if (item?.type?.toLowerCase() == "contentrequestdemo") {
          if (config?.ctaRequestDemo?.ctaRequestDemoDisplay) {
            eachMeuItem = {
              key: item?.type?.toLowerCase(),
              name: item?.customData?.displayName,
              status: false,
              disabled: !config?.ctaRequestDemo?.ctaRequestDemoEnabled,
              onClick: () => {
                onClickEachCTA(item);
              },
              content: (
                <div tabIndex={0} className="moreIcons">
                  <p>{item?.customData?.displayName}</p>
                </div>
              ),
            };
            menuArr.push(eachMeuItem);
          }
        }
        if (item?.type?.toLowerCase() == "contenttalktoyoureyteam") {
          if (config?.ctaTalkToEY?.ctaTalkToEYDisplay) {
            eachMeuItem = {
              key: item?.type?.toLowerCase(),
              name: item?.customData?.displayName,
              status: false,
              disabled: !config?.ctaTalkToEY?.ctaTalkToEYEnabled,
              onClick: () => {
                onClickEachCTA(item);
              },
              content: (
                <div tabIndex={0} className="moreIcons">
                  <p>{item?.customData?.displayName}</p>
                </div>
              ),
            };
            menuArr.push(eachMeuItem);
          }
        }
      });
      setCTAMenu(menuArr);
    }
  }, []);

  const renderContent = (item, isTriggered) => {
    return (
      <div tabIndex={0} className="moreIcons">
        <p>{item?.name}</p>
        {isTriggered == true ? <AiFillCheckCircle color="green" /> : null}
      </div>
    );
  };

  const onClickEachCTA = (item, status) => {
    console.log(item);
    if (status == true) {
      setOpenCTADialog(true);
      setCTAActionId(item?.key);
    } else {
      onClickContinueCTA(item);
    }
  };
  const onClickContinueCTA = async (item) => {
    openCTADialog == true && setOpenCTADialog(false);
    setCTAActionId("");
    setIsLoading(true);
    await postData(
      {
        metaDataId: cardItem.metaDataId,
        cardTitle: cardItem.title,
      },
      APIEndPoints.TRIGGER_CTA_ACTION(
        props.contentType,
        userInfo?.teams?.companyId,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : props?.item?.contentSecurity?.availability?.domainLevel ==
            Constant.LOCAL
          ? false
          : null,
        openCTADialog == true ? CTAActionId : item?.key || item?.type
      )
    )
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        if (response.data == true) {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.CTAACTION_SUCCESS,
            type: "Success",
            isFailed: false,
          });
        } else {
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: Constant.CTAACTION_FAILURE,
            type: "fail",
            isFailed: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: err?.response?.data?.Errors
            ? err?.response?.data?.Errors
            : Constant.CTAACTION_FAILURE,
          type: "fail",
          isFailed: false,
        });
      });
  };
  const onClickCTAMenu = async () => {
    let ctaActions = CTAMenu?.filter((item) => {
      return item?.key?.toLowerCase() != "contentregister";
    });
    if (ctaActions?.length > 0) {
      await postData(
        {
          metaDataId: cardItem.metaDataId,
          ctaActions: ctaActions?.map((item) => {
            return item?.key;
          }),
        },
        APIEndPoints.CTA_ACTION_STATUS(
          props?.contentType,
          props?.item?.contentSecurity?.availability?.domainLevel ==
            Constant.GLOBAL
            ? true
            : props?.item?.contentSecurity?.availability?.domainLevel ==
              Constant.LOCAL
            ? false
            : null,
          userInfo?.teams?.companyId
        )
      )
        .then((response) => {
          let ctaMenu = [...CTAMenu];
          response.data.map((item) => {
            let index = ctaMenu?.findIndex((menu) => {
              return menu.key == item?.key?.toLowerCase();
            });
            if (index != -1) {
              ctaMenu[index].content = renderContent(
                ctaMenu[index],
                item?.isTriggered
              );
              ctaMenu[index].status = item?.isTriggered;
              ctaMenu[index].onClick = () =>
                onClickEachCTA(item, item?.isTriggered);
            }
          });
          setMenuItems(() => response.data);
          setCTAMenu(() => ctaMenu);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const openRegisterUrl = (URL) => {
    console.log(URL);
    try {
      userInfo.loggedInWeb === true
        ? window.open(atob(URL), "_blank")
        : app.openLink(atob(URL));
    } catch {
      userInfo.loggedInWeb === true
        ? window.open(URL, "_blank")
        : app.openLink(URL);
    }
  };
  return (
    <>
      {
        <div className="layoverBackground">
          <DialogBox
            closeOnOutsideClick={false}
            content={
              <div className={isLoading && "loaderLayoverCTA"}>
                <ReusableLoader isOverlay={true} />
              </div>
            }
            backdrop={true}
            openDialog={isLoading}
            className={"likeLayover"}
          />
        </div>
      }
      <FormContext>
        {/* Comment:Included a class "leftAlignActionBar" for Left Alignment of Card Icons in Search Library Page */}

        <div className="actionButtons">
          <div
            // className={
            //   props?.leftAlignActionBarCss == true
            //     ? "leftAlignActionBar"
            //     : "actionBar"
            // }
            className="actionBar"
          >
            {props.contentType == 7 &&
            config?.viewUniqueResponses?.viewUniqueResponsesDisplay ? (
              <div
                className="visibility"
                disabled={
                  !config.viewUniqueResponses.viewUniqueResponsesEnabled
                }
              >
                <MdOutlineHowToVote />
                <span className="views-span">
                  <small>
                    {cardItem?.contentInfo[0]?.content?.pollData
                      ?.metaDataOptionMetrics?.totalResponses
                      ? cardItem?.contentInfo[0]?.content?.pollData
                          ?.metaDataOptionMetrics?.totalResponses
                      : 0}
                  </small>
                </span>
              </div>
            ) : (
              ""
            )}
            {props.contentType != 7 && config?.view?.viewDisplay ? (
              <div className="visibility" disabled={!config.view.viewEnabled}>
                <MdVisibility />
                <span className="views-span">
                  <small>{views}</small>
                </span>
              </div>
            ) : (
              ""
            )}
            {config?.like?.likeDisplay ? (
              <div
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && config.like.likeEnabled) {
                    onLikeButtonClick();
                  }
                }}
              >
                {!waitingFlag &&
                  (isLike ? (
                    <CustomizedToolTip
                      tabIndex={0}
                      normalTooltip={true}
                      position="below"
                      align="center"
                      content={"Like"}
                    >
                      <MdThumbUp
                        data-testid="thumbsupIconOne"
                        className={`actionIcon ${
                          waitingFlag && "transitioned"
                        } ${!config.like.likeEnabled && "actionIcon-disable"}`}
                        onClick={
                          config.like.likeEnabled
                            ? onLikeButtonClick
                            : undefined
                        }
                      />
                    </CustomizedToolTip>
                  ) : (
                    <CustomizedToolTip
                      tabIndex={0}
                      normalTooltip={true}
                      position="below"
                      align="center"
                      content={"Like"}
                    >
                      <BiLike
                        data-testid="thumbsupIconTwo"
                        className={`actionIcon ${
                          waitingFlag && "transitioned"
                        } ${!config.like.likeEnabled && "actionIcon-disable"}`}
                        onClick={
                          config.like.likeEnabled
                            ? onLikeButtonClick
                            : undefined
                        }
                      />
                    </CustomizedToolTip>
                  ))}
                {waitingFlag && (
                  <img
                    className="transitioned"
                    src={require("../../assets/like.svg").default}
                  />
                )}
                <span
                  className={`${
                    !config.like.likeEnabled && "actionIcon-disable"
                  }`}
                >
                  <b>{like}</b>
                </span>
              </div>
            ) : (
              ""
            )}
            {(userInfo.loggedInWeb || loggedInM365Disable) &&
            config?.share?.shareDisplay ? (
              <div tabIndex={0} className="action-button-div mail-cursor">
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Mail"}
                >
                  <MdMailOutline
                    data-testid="sendLinkIcon"
                    className={`${
                      !userInfo.loggedInWeb &&
                      !loggedInM365Disable &&
                      "disable-web-version"
                    }`}
                    onClick={() => {
                      hideSendLinkModal();
                    }}
                  />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}
            {(userInfo.loggedInWeb || loggedInM365Disable) &&
            config?.calendar?.calendarDisplay &&
            !userInfo?.isLoggedInAsGuest ? (
              <div tabIndex={0} className="action-button-div">
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Calendar"}
                >
                  <CalenderPopup {...props} />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}
            {(userInfo.loggedInWeb || loggedInM365Disable) &&
            config?.share?.shareDisplay ? (
              <div
                tabIndex={0}
                className="action-button-div"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && config.share.shareEnabled) {
                    setisShareModalOpen(!isShareModalOpen);
                  }
                }}
              >
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Share"}
                >
                  <MdSend
                    className={` actionIcon ${
                      !config.share.shareEnabled && "actionIcon-disable"
                    }`}
                    onClick={() =>
                      config.share.shareEnabled
                        ? setisShareModalOpen(!isShareModalOpen)
                        : undefined
                    }
                  />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}

            {config?.chat?.chatDisplay && checkIfDeviceIsMob() ? (
              <div
                tabIndex={0}
                className={`action-button-div ${
                  (userInfo.loggedInWeb || loggedInM365Disable) &&
                  "disable-web-version"
                }`}
              >
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Chat"}
                >
                  <Chat {...props} />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}
            {config?.chat?.chatDisplay &&
            !checkIfDeviceIsMob() &&
            !userInfo?.isLoggedInAsGuest ? (
              <div
                tabIndex={0}
                className={`action-button-div ${
                  (userInfo.loggedInWeb || loggedInM365Disable) &&
                  "disable-web-version"
                }`}
              >
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Chat"}
                >
                  <MdChatBubble
                    data-testid="chatIcon"
                    className={`actionIcon ${
                      !config?.chat?.chatEnabled && "actionIcon-disable"
                    }`}
                    onClick={() => {
                      userInfo.loggedInWeb || loggedInM365Disable
                        ? setWebVersionDisable(true)
                        : shareToTeams();
                    }}
                  />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}
            {!userInfo.loggedInWeb &&
            !loggedInM365Disable &&
            config?.calendar?.calendarDisplay &&
            !userInfo?.isLoggedInAsGuest ? (
              <div tabIndex={0} className="action-button-div">
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Calendar"}
                >
                  <CalenderPopup {...props} />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}

            {!userInfo.loggedInWeb &&
            !loggedInM365Disable &&
            config?.share?.shareDisplay ? (
              <div
                tabIndex={0}
                className="action-button-div"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && config.share.shareEnabled) {
                    setisShareModalOpen(!isShareModalOpen);
                  }
                }}
              >
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Share"}
                >
                  <MdSend
                    className={` actionIcon ${
                      !config.share.shareEnabled && "actionIcon-disable"
                    }`}
                    onClick={() =>
                      config.share.shareEnabled
                        ? setisShareModalOpen(!isShareModalOpen)
                        : undefined
                    }
                  />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}
            {props.contentType != 7 &&
            config?.download?.downloadDisplay &&
            isAttachment &&
            !props.showMoreButton &&
            !checkIfDeviceIsMob() ? (
              <div
                tabIndex={0}
                className="action-button-div"
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    config.download.downloadEnabled &&
                    isAttachment
                  ) {
                    handleAttachmentDownload();
                  }
                }}
              >
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Download"}
                >
                  <MdDownload
                    data-testid="downloadIcon"
                    onClick={
                      config.download.downloadEnabled && isAttachment
                        ? handleAttachmentDownload
                        : undefined
                    }
                    className={`actionIcon ${
                      config.download.downloadEnabled && isAttachment
                        ? ""
                        : "actionIcon-disable"
                    }`}
                  />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}

            {props?.contentType === 4 &&
            props?.fromPinLibraryDetails &&
            props?.isValidTemplate &&
            !checkIfDeviceIsMob() ? (
              <div
                tabIndex={0}
                className="action-button-div"
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    config.export.exportEnabled &&
                    props?.isValidTemplate
                  ) {
                    handleExportVisualization();
                  }
                }}
              >
                <CustomizedToolTip
                  tabIndex={0}
                  normalTooltip={true}
                  position="below"
                  align="center"
                  content={"Export to PowerPoint"}
                >
                  <HiDownload
                    data-testid="downloadIcon"
                    onClick={
                      config.export.exportEnabled && props?.isValidTemplate
                        ? handleExportVisualization
                        : undefined
                    }
                    className={`actionIcon ${
                      !config.export.exportEnabled && "actionIcon-disable"
                    }`}
                  />
                </CustomizedToolTip>
              </div>
            ) : (
              ""
            )}

            {isShareModalOpen && (
              <SharePopup
                isModalOpen={isShareModalOpen}
                hideModal={hideShareModal}
                showCopyLinkPopUpModal={hideCopyLinkModal}
                showSendLinkPopUpModal={hideSendLinkModal}
                {...props}
              />
            )}
            {isCopyLinkModalOpen && (
              <CopyLinkPopUp
                isModalOpen={isCopyLinkModalOpen}
                hideModal={hideCopyLinkModal}
                {...props}
              />
            )}
            {isSendLinkModalOpen && (
              <SendLinkPopUp
                isModalOpen={isSendLinkModalOpen}
                hideModal={hideSendLinkModal}
                openPrevModal={hideShareModal}
                title={props.title}
                img={props.img}
                link={props.link}
                description={props.description}
                {...props}
              />
            )}
            {props.contentType != 7 &&
              showMoreButton &&
              config?.download?.downloadDisplay &&
              !checkIfDeviceIsMob() &&
              isAttachment && (
                <div
                  tabIndex={0}
                  className="action-button-div"
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      config.download.downloadEnabled &&
                      isAttachment
                    ) {
                      handleAttachmentDownload();
                    }
                  }}
                >
                  <CustomizedToolTip
                    tabIndex={0}
                    normalTooltip={true}
                    position="below"
                    align="center"
                    content={"Download"}
                  >
                    <MdDownload
                      data-testid="downloadIcon"
                      onClick={
                        config.download.downloadEnabled && isAttachment
                          ? handleAttachmentDownload
                          : undefined
                      }
                      className={`actionIcon ${
                        config.download.downloadEnabled && isAttachment
                          ? ""
                          : "actionIcon-disable"
                      }`}
                    />
                  </CustomizedToolTip>
                </div>
              )}
          </div>
          {props.contentType != 7 &&
            showMoreButton &&
            // config?.register?.registerDisplay &&
            // regURL &&
            // regURL != "" &&
            CTAMenu &&
            CTAMenu?.length > 0 && (
              <MenuButton
                trigger={
                  <img
                    src={touchIcon}
                    alt="touch"
                    className="touchMenu"
                    onClick={onClickCTAMenu}
                  />
                }
                menu={CTAMenu}
                className={`ctaMenu ${
                  cardItem?.contentInfo?.[0]?.contentSourceType ==
                  Constant.OTHERS
                    ? `ui-pin-icon-cursor`
                    : `ui-pin-icon-cursor`
                }`}
              />
            )}
        </div>
      </FormContext>
      {
        <DialogBox
          cancelButton={"Cancel"}
          onCancelMethod={() => {
            setOpenCTADialog(false);
          }}
          closeOnOutsideClick={false}
          confirmButton={"Continue"}
          onConfirmMethod={onClickContinueCTA}
          content={
            <p>
              Attention! Our records indicate that you have already performed
              this action. Do you wish to proceed with this request again?
              Please note, this will notify the app owners with another email.
            </p>
          }
          headerName={"Request Already Submitted"}
          headerActionName={{
            icon: <GrClose />,
            title: "Close",
            onClick: () => {
              setOpenCTADialog(false);
            },
          }}
          backdrop={true}
          openDialog={openCTADialog}
          className={`dialogDeleteStyles`}
        />
      }
      <DialogBox
        closeOnOutsideClick={false}
        content={<Web_Version_Message />}
        headerName={
          <div className="dialogBoxHeader">
            <Image
              alt=""
              src={require("../../assets/EY_Logo.png")}
              fit="cover"
              id={"dialogBoxHeaderImg"}
            />
            <div>
              <strong className="dialogBoxHeaderTitle">
                EY Catalyst Connect - Install App
              </strong>
            </div>
          </div>
        }
        headerActionName={{
          icon: <GrClose />,
          title: "Close",
          className: "dialogBoxCloseIcon",
          onClick: () => setWebVersionDisable(false),
        }}
        backdrop={true}
        openDialog={webVersionDisable}
        className={"dialogStyles"}
        cancelButton={"Close"}
        onCancelMethod={() => setWebVersionDisable(false)}
      />
    </>
  );
};

export default ActionBar;
