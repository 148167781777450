/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { MdLibraryBooks } from "react-icons/md";
import { app } from "@microsoft/teams-js";
import { useMenuContext } from "../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import ComingSoon from "../comminsoon/comingSoon";
import "./commonLayout.css";
import { UseContentCurationContext } from "../../context/contentcurationcontext/contentCurationContext";
import { Link, useParams } from "react-router-dom";
import { UseSearchContext } from "../../context/searchcontext/searchContext";
import { capitalizeWords } from "../../utility/utility";
import { scrollIntoViewWithOffset } from "../../utility/menuscroll";
import Badge from "../../components/sidebar/badge/badge";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";
import { useNavigate } from "react-router-dom";
import sideNavMainMenuData from "../../data/menu/sideNavMainMenuData";
import CustomizedToolTip from "../tooltip/toolTip";
import MyShortcuts from "../../components/myshortcuts/myShortcuts";
import { UseMyShortcutsContext } from "../../context/myshortcutcontext/myShortcutsContext";

function CommonChild(props) {
  const navigate = useNavigate();
  const { children, subMenuName, id } = props;
  const { menuState } = useMenuContext();
  const { userInfo } = UseUserInfoContext();
  const { myShortcutsData } = UseMyShortcutsContext();
  const { handleContentCurationData } = UseContentCurationContext();
  const { handleMenuInfo } = UseContactUsContext();
  //let disableLibraryIcon = subMenuName !== "Tax Alerts";
  const [newButtonConfig, setNewButtonConfig] = useState({});
  const [shortcutButtonConfig, setShortcutButtonConfig] = useState({});
  const [totalTileCount, setTotalTileCount] = useState(0);
  const [isAddToShortcuts, setIsAddToShortcuts] = useState(false);
  const [isRemoveFromShortcuts, setIsRemoveFromShortcuts] = useState(false);
  const [subMenuItemId, setSubMenuItemId] = useState({});
  const [shortcutsId, setShortcutsId] = useState({});
  const [isMyShortcutsOpen, setIsMyShortcutsOpen] = useState(false);
  const [subMenuItemName, setSubMenuItemName] = useState({});
  const [LibraryButtonConfig, setLibraryButtonConfig] = useState({
    libraryDisplay: false,
    libraryEnabled: false,
  });

  let { handleSelectedItemMenu } = UseSearchContext();

  const handleMenuChange = async (e) => {
    e.preventDefault();
    handleMenuInfo({ menuName: "Search", subMenuName: subMenuName, sourceComponent: "" });
    handleSelectedItemMenu(subMenuName);

    userInfo.loggedInWeb === true
      ? navigate("/search", {
          state: {
            subPageId: subMenuName,
          },
        })
      : app.getContext().then((item) => {
          navigate("/search", {
            state: {
              subPageId: subMenuName,
            },
          });
        });
  };

  const handleAddNewForm = () => {
    handleContentCurationData({
      formTitle: subMenuName,
      pageId: props?.id,
      contentTypeId: props.contentTypeId,
      metaDataId: 0,
    });
  };
  const handleClickMyShortcuts = () => {
    const filterShortcut = myShortcutsData.shortcutsList?.filter(
      (item) => item?.subMenuItemId === props?.subMenuItemId
    );
    if (filterShortcut.length === 0) {
      setIsAddToShortcuts(true);
      setIsRemoveFromShortcuts(false);
    } else {
      setShortcutsId(filterShortcut[0]?.userShortcutId);
      setIsAddToShortcuts(false);
      setIsRemoveFromShortcuts(true);
    }
    setSubMenuItemId(props?.subMenuItemId);
    setSubMenuItemName(subMenuName);
    setIsMyShortcutsOpen(!isMyShortcutsOpen);
  };

  function handleScroll(e, link) {
    e.preventDefault();
    try {
      // document.getElementById(link.to).scrollIntoView();
      userInfo.loggedInWeb === true
        ? scrollIntoViewWithOffset(link.to, -80)
        : scrollIntoViewWithOffset(link.to, -30);
    } catch {}
  }

  const contentMenu = [
    ...sideNavMainMenuData?.[1]?.menu?.map((i) => i?.title),
    "Apps",
    "Visualizations",
  ];

  const params = useParams();

  return userInfo?.teams?.featureFlagsObj[capitalizeWords(subMenuName)]
    ?.isEnabled === true ||
    subMenuName == "Data Drop" ||
    subMenuName == "User Groups" ||
    subMenuName == "Create Report" ||
    subMenuName == "Data Library" ||
    subMenuName == "Report Library" ||
    subMenuName == "Visualization Library" ? (
    <div style={{ minHeight: "100vh", position: "relative" }} id={id}>
      {subMenuName && menuState?.menu?.name !== "Settings" && (
        <div className="common_container_subtitle">
          <div className="common_container_rightMenu">
            <div className="divTxtSubmenu">
              <header style={{ maxWidth: "100%" }}>
                <p style={{ display: "flex" }}>
                  {props?.appsDetails ? (
                    <>
                      <CustomizedToolTip content={params?.title}>
                        <span className="commonTitleEllipses showEllipses">
                          {params?.title}
                        </span>
                      </CustomizedToolTip>
                      <div>{totalTileCount !== 0 ? "(" + totalTileCount + ")" : ""}</div>
                    </>
                  ) : (
                    <CustomizedToolTip content={subMenuName}>
                      <span className="commonTitleEllipses showEllipses">
                        {subMenuName}
                      </span>
                    </CustomizedToolTip>
                  )}{" "}
                  {props?.badge && <Badge badge={props.badge} />}
                </p>
              </header>
            </div>
            {contentMenu?.includes(menuState?.menu?.name) &&
              subMenuName !== "Bulletin Board" &&
              subMenuName !== "Tax On-Demand" &&
              newButtonConfig?.addNewDisplay &&
              !props?.appsDetails && (
                <div
                  className={`${
                    userInfo?.teams?.isGlobalContentCompany
                      ? "globalDivLink"
                      : "divLink"
                  }`}
                >
                  <Link
                    to={
                      subMenuName?.toLowerCase() === "polls"
                        ? "/newPollCuration"
                        : `/contentCuration`
                    }
                    onClick={handleAddNewForm}
                    className={`${
                      subMenuName?.toLowerCase() === "polls"
                        ? ""
                        : !newButtonConfig?.addNewEnabled && "inactive"
                    }`}
                  >
                    {userInfo?.teams?.isGlobalContentCompany
                      ? "Add Global"
                      : "Add Local"}
                  </Link>
                </div>
              )}
            {subMenuName === "User Groups" &&
              newButtonConfig?.addNewCustomUserGroupDisplay && (
                <div
                  className={
                    // `${userInfo?.teams?.isGlobalContentCompany ? "globalDivLink" : "divLink"}`
                    "divLink"
                  }
                >
                  <Link
                    to={"/customUserGroupForm"}
                    onClick={handleAddNewForm}
                    className={`${
                      subMenuName?.toLowerCase() === "polls"
                        ? ""
                        : !newButtonConfig?.addNewCustomUserGroupEnabled &&
                          "inactive"
                    }`}
                  >
                    {/* {userInfo?.teams?.isGlobalContentCompany ? "Add Global" : "Add Local"} */}
                    {"Add New"}
                  </Link>
                </div>
              )}
            {contentMenu?.includes(menuState?.menu?.name) &&
              (subMenuName === "Visualization Library" ||
                shortcutButtonConfig?.myShortcutsDisplay) && (
                <div>
                  <a
                    className={`shortcutIconDiv`}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        (subMenuName === "Visualization Library" ||
                          shortcutButtonConfig?.myShortcutsEnabled) &&
                          handleClickMyShortcuts();
                      }
                    }}
                    onClick={(e) => {
                      (subMenuName === "Visualization Library" ||
                        shortcutButtonConfig?.myShortcutsEnabled) &&
                        handleClickMyShortcuts();
                    }}
                  >
                    {myShortcutsData.shortcutsList?.filter(
                      (item) => item?.subMenuItemId === props?.subMenuItemId
                    ).length === 0 ? (
                      <CustomizedToolTip content={"Add to My Shortcuts"}>
                        <img
                          className="addRemoveShortcutIcon"
                          src={require("../../assets/AddShortcut.png")}
                        />
                      </CustomizedToolTip>
                    ) : (
                      <CustomizedToolTip content={"Remove from My Shortcuts"}>
                        <img
                          className="addRemoveShortcutIcon"
                          src={require("../../assets/RemoveShortcut.png")}
                        />
                      </CustomizedToolTip>
                    )}
                  </a>
                </div>
              )}
          </div>
          {isMyShortcutsOpen && (
            <MyShortcuts
              isAddToShortcuts={isAddToShortcuts}
              isRemoveFromShortcuts={isRemoveFromShortcuts}
              subMenuItemId={subMenuItemId}
              shortcutsId={shortcutsId}
              subMenuItemName={subMenuItemName}
              isMyShortcutsOpen={isMyShortcutsOpen}
              setIsAddToShortcuts={setIsAddToShortcuts}
              setIsRemoveFromShortcuts={setIsRemoveFromShortcuts}
              setIsMyShortcutsOpen={setIsMyShortcutsOpen}
            />
          )}
          {props?.link &&
            userInfo?.teams?.featureFlagsObj[props.link?.title]?.isEnabled &&
            !props?.appsDetails && (
              <a
                className="common_container_leftMenu"
                // href={`#${props?.link?.to}`}
                onClick={(e) => {
                  handleScroll(e, props.link);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleScroll(e, props.link);
                  }
                }}
              >
                <p tabIndex={0} className="libraryIcon">
                  {props.link?.title}
                </p>
              </a>
            )}
          {contentMenu?.includes(menuState?.menu?.name) &&
          menuState?.menu?.name !== "Apps" &&
          LibraryButtonConfig?.libraryDisplay ? (
            // && subMenuName !== "Tax Agenda"
            <a
              className="common_container_leftMenu"
              //href="#"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  LibraryButtonConfig?.libraryEnabled && handleMenuChange(e);
                }
              }}
              onClick={(e) => {
                LibraryButtonConfig?.libraryEnabled && handleMenuChange(e);
              }}
            >
              <MdLibraryBooks
                tabIndex={0}
                size={22}
                className="libraryIcon"
                title="Library"
              />
            </a>
          ) : (
            ""
          )}
        </div>
      )}

      {children !== undefined ? (
        // <div className="common_container_cscontent">{children}</div>
        <div className="common_container_cscontent">
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              newButtonConfig: newButtonConfig,
              setNewButtonConfig: setNewButtonConfig,
              shortcutButtonConfig: shortcutButtonConfig,
              setShortcutButtonConfig: setShortcutButtonConfig,
              LibraryButtonConfig: LibraryButtonConfig,
              setLibraryButtonConfig: setLibraryButtonConfig,
              totalTileCount: totalTileCount,
              setTotalTileCount: setTotalTileCount,
              setIsAddToShortcuts: setIsAddToShortcuts,
              setIsRemoveFromShortcuts: setIsRemoveFromShortcuts,
            })
          )}
        </div>
      ) : (
        <ComingSoon />
      )}
    </div>
  ) : (
    ""
  );
}

export default CommonChild;
