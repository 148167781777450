import React, { useState, useEffect } from "react";
import PopupBox from "../../../../commonui/popup/popup";
import ReusableLoader from "../../../../commonui/reusableloader/reusableLoader";
import { UseformDataContext } from "../../../../context/genericdatacontext/formdatacontext";
import { postData, getData } from "../../../../services/customApis";
import APIEndPoints from "../../../../utility/apiendpoints";
import SendLink from "./sendLink";
import { AiOutlineWarning } from "react-icons/ai";
import { UseToasterNotificationsContext } from "../../../../context/toasternotificationscontext/toasterNotificationsContext";
import { UseUserInfoContext } from "../../../../context/usercontext/userContext";
import Constant from "../../../../utility/constants";
import { getContentTypeNamesById2 } from "../../../../utility/utility";
import TelemetryEventNames from "../../../../data/apptelemetry/appTelemetry.const";
import { TrackEvent } from "../../../../services/appInsights";
import { app } from "@microsoft/teams-js";
import "./sendLink.css";

function SendLinkPopUp(props) {
  const { formInput, handleFormDataIuput } = UseformDataContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { userInfo } = UseUserInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, SetError] = useState(false);
  const [errorMsg, SetErrorMsg] = useState("");
  const [showDeepLink, setShowDeepLink] = useState(true);
  const [loggedInM365Disable, setloggedInM365Disable] = useState(false);

  useEffect(() => {
    if (formInput.linkViewClosed === true) {
      setShowDeepLink(false);
    } else {
      setShowDeepLink(true);
    }
  }, [formInput.linkViewClosed]);

  useEffect(() => {
    app.getContext().then((context) => {
      if (
        context?.app?.host?.name === "Outlook" ||
        context?.app?.host?.name === "Office"
      ) {
        setloggedInM365Disable(true);
      }
    });
  }, []);

  const handleSendMailToBackEnd = async () => {
    //SEND TO BACK END FUNCTIONALITY
    setIsLoading(true);
    let formInputObj = { ...formInput };
    let recipient = { userEmailList: [] };
    let userGroupDetails = [];
    let allEmail = formInputObj.recipient.userEmailList;
    let allIdsForUserGroup = formInputObj.recipient.userGroupList;
    delete formInputObj.recipient;
    allEmail.map((item) => {
      recipient.userEmailList.push(item);
      return item;
    });
    allIdsForUserGroup.map((item) => {
      let ugobj = {
        userGroupId: parseInt(item.Id),
        userGroupName: item.Name,
      };
      userGroupDetails.push(ugobj);
      return item;
    });

    let newObj = { ...formInputObj, recipient, userGroupDetails };
    restructureTheEmailPayload(newObj);
    delete newObj["body"];
    const newCopyObj =
      newObj["catalystUserText"] === undefined
        ? ""
        : newObj["catalystUserText"];
    newObj["catalystTextwithUrl"] = btoa(
      unescape(
        encodeURIComponent(
          newObj["catalystTextwithUrl"].replace(
            "{text_content_to_be_replaced}",
            newCopyObj
          )
        )
      )
    );
    delete newObj["catalystUserText"];
    delete newObj["catalystTextWithDeepLink"];
    delete newObj["catalystDetails"];
    await postData(
      {
        ...newObj,
        contentType: props.contentType,
        metaDataId: props.id,
        ccRecipient: {
          ccUserEmailList: [],
        },
      },
      APIEndPoints.SEND_EMAIL(
        props.contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        //console.log(response);
        //handleSuccessNotification();
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: "Your email has been sent.",
          type: "Success",
          isFailed: false,
        });
        updateShareCounter();
        setIsLoading(false);
        props.hideModal();
        handleFormDataIuput({});
      })
      .catch((err) => {
        //console.log("error", error);
        //handleErrorNotification();
        setIsLoading(false);
        SetError(true);
        SetErrorMsg(err?.response?.data?.Errors);
      });
  };

  const restructureTheEmailPayload = (obj) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(obj.catalystTextwithUrl, "text/html");
    if (formInput.isImageClosed) {
      doc.body.getElementsByClassName("rowContentClass")[0].remove();
    } else {
      doc.body.getElementsByClassName("img_catalyst")[0].src = "cid:itemImage";
    }

    let removedTagsFromImg = doc.body.innerHTML
      .replace(/[\n\t]+/g, "")
      .replace(/\s+/g, " ")
      .replace('<o:p=""', "/");
    let removeunWantedTag = removedTagsFromImg
      .replace("<o:p>&nbsp;</o:p>", "")
      .replace("<o:p>", "")
      .replace("</o:p>", "")
      .replace("&nbsp;", "");

    // encode the title as well
    obj.catalystTextwithUrl = removeunWantedTag;
    if (obj.catalystImage) {
      let splittedImage = obj.catalystImage.split(",")?.[1];
      obj.catalystImage = btoa(unescape(encodeURIComponent(splittedImage)));
    }
    obj.catalystTitle =
      props?.contentType == 7
        ? obj.catalystTitle
        : btoa(unescape(encodeURIComponent(obj.catalystTitle)));
  };

  const handleCancel = () => {
    props.hideModal();
    !userInfo.loggedInWeb && !loggedInM365Disable && props.openPrevModal();
    setShowDeepLink(true);
    handleFormDataIuput({});
  };

  const updateShareCounter = async () => {
    const shareCounterData = {
      metaDataId: props.item.metaDataId,
    };
    console.log(shareCounterData);
    await postData(
      shareCounterData,
      APIEndPoints.CONTENT_SHARE_COUNTER(
        props.contentType,
        props?.item?.contentSecurity?.availability?.domainLevel ==
          Constant.GLOBAL
          ? true
          : false,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        //TESTED
        TrackEvent(TelemetryEventNames.UCC_CONTENT_SHARE_LINK_EVENT, userInfo, {
          SectionName:
            props?.contentType == 4
              ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
              : TelemetryEventNames.SECTION_TYPE_CONTENT,
          ContentTypeId: props.contentType.toString() ?? "",
          ContentTypeName: getContentTypeNamesById2(props.contentType) ?? "",
          MetadataId: props.item.metaDataId.toString(),
          UniqueId: props.item.contentUniqueId ?? "",
          Title: props.item.title ?? "",
          PublishDate: props.item.publishedDate ?? "",
        });
        //pass
        // console.log(response);
      })
      .catch((error) => {
        //pass
      });
  };

  const formValidation = () => {
    if (formInput.recipient !== undefined) {
      if (
        (formInput.recipient.userEmailList.length !== 0 ||
          formInput.recipient?.userGroupList?.length !== 0) &&
        showDeepLink
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const openMailInOutlook = async () => {
    let _subject =
      props.contentType === 7
        ? atob(unescape(decodeURIComponent(formInput.catalystTitle))).substring(
            0,
            250
          )
        : formInput.catalystTitle.substring(0, 250);

    let _content =
      formInput.catalystUserText !== undefined
        ? formInput.catalystUserText.replace(/<[^>]*>?/gm, "")
        : "";

    let _toRecipient =
      formInput.recipient !== undefined
        ? formInput.recipient.userEmailList.join(";")
        : [];

    if (
      formInput.recipient !== undefined &&
      formInput.recipient.userGroupList.length > 0
    ) {
      let userGroupDetails = formInput.recipient.userGroupList;
      let userGroupEmailIds = await Promise.all(
        userGroupDetails.map(async (item) => {
          try {
            let res = await getData(
              APIEndPoints.GET_CUSTOM_USER_GROUP_BY_ID(
                userInfo.teams.companyId,
                item.Id
              )
            );
            return res?.data?.userDetails?.map((item, i) => item.emailId) || "";
          } catch (err) {
            return "";
          }
        })
      );
      _toRecipient = _toRecipient.concat(
        ";",
        userGroupEmailIds.flat().join(";")
      );
    }

    let weblink = "";
    userInfo.loggedInWeb === true
      ? (weblink = atob(decodeURIComponent(formInput.catalystContentWebLink)))
      : (weblink =
          atob(decodeURIComponent(formInput.catalystContentDeepLink)).split(
            "?"
          )[0] +
          "%3F" +
          atob(decodeURIComponent(formInput.catalystContentDeepLink)).split(
            "?"
          )[1]);

    let text =
      "Hey there... check out this content on Catalyst! Click the link below or copy/paste it into your web-browser. ";

    _content +=
      "%0D%0A%0D%0A" + text + "%0D%0A%0D%0A" + encodeURIComponent(weblink);

    //setIsLoading(true);
    if (userInfo.loggedInWeb === true) {
      window.open(
        "mailto:" +
          _toRecipient +
          "?subject=" +
          _subject +
          "&body=​" +
          _content,
        "_blank"
      );
      setTimeout(() => {
        handleCancel();
      }, 1500);
    } else {
      await app.initialize().then(async () => {
        app
          .openLink(
            "mailto:" +
              _toRecipient +
              "?subject=" +
              _subject +
              "&body=​" +
              _content
          )
          .then((item) => {
            //setIsLoading(false);
            console.log("Outlook mail is properly opened");
            handleCancel();
          })
          .catch((err) => {
            console.log(err);
            //setIsLoading(false);
          });
      });
    }
  };

  return (
    <PopupBox
      title="Send link"
      isFooter={true}
      isBorderNeeded={true}
      isModalOpen={props.isModalOpen}
      hideModal={handleCancel}
      sendButtonDisabled={formValidation()}
      onClick={handleSendMailToBackEnd}
      className="sendLinkPopupStyle"
      isOpenInOutlook={true}
      onClickOpenInOutlook={openMailInOutlook}
    >
      {isLoading && <ReusableLoader isOverlay={true} isMail={true} />}
      <SendLink
        img={props.img}
        link={props.link}
        title={props.title}
        description={props.description}
        showPreviewLink={showDeepLink}
        {...props}
      />
      {!showDeepLink && (
        <div className="chatValidationError">
          <p>
            <AiOutlineWarning />
            {Constant.CHAT_VALIDATION_MESSAGE}
          </p>
        </div>
      )}
      {error && (
        <div className="mailServerError">
          <p>
            <AiOutlineWarning />
            {errorMsg}
          </p>
        </div>
      )}
    </PopupBox>
  );
}

export default SendLinkPopUp;
